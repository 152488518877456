import { AdjudicationSummaryItem } from "../../api";

interface DocumentData {
  [key: string]: {
    [key: string]: string | number;
  };
}

interface SummaryData {
  [key: string]: string;
}
interface ProcedureRequest {
  overview: string;
  adjudication_summary: string[];
}

export const hardCodedPBMDocuments:any=[
  "Enrolee Info.pdf",
  "case1.pdf",
  "case4.pdf",
  "case3.pdf",
  "case2.pdf"
]

export const extractValues: DocumentData = {
  "Enrolee Info.pdf": {
    "Date of Birth": "02/21/2000",
    "Pharmacy Name": "Rite Aid #05319",
    "Pharmacy NPI": "1871602193",
    Prescriber: "Ruth Adeptun",
    "Prescriber NPI": "1518362862",
    "Medication and Strength": "Vraylar 1.5 mg capsule",
    "Qty/Days Supply": "30/30",
    "Diagnosis code": "F31.81",
    "Diagnosis code description": "bipolar II disorder",
  },
  "146132852320240319.pdf": {
    "Member Name": "Kelly Kurtz SR",
    DOB: "03/08/1976",
    " Member ID": "110000174470",
    "Clinic Name": "Community Health Asc. of Spokane CHAS North County Clinic",
    "PCP Name": "John Kabosky",
    "PCP address": "401 Main St",
    City: "Deer Park",
    State: "WA",
    ZIP: "99006",
  },
  "188685422520240319.pdf": {
    "Member Name": "Yaretzi Cruz Jimenez",
    DOB: "12/22/2011",
    " Member ID": "110001453417",
    "Clinic Name": "Quincy Community Health Center",
    "PCP Name": "India Wolfendale, PA-C",
    "PCP address": "1450 IST AVE SW",
    City: "Quincy",
    State: "WA",
    ZIP: "98848",
  },
  "156116407220240319.pdf": {
    "Member Name": "Frankie C Sweowat - Kheel",
    DOB: "8/11/23",
    " Member ID": "110066481189",
    "Clinic Name": "Community Health Asc. of Spokane CHAS Perry St. Clinic",
    "PCP Name": "Deborah Wiser, MD",
    "PCP address": "817 S Perry St Suite B ",
    City: "Spokane",
    State: "WA",
    ZIP: "99202",
  },
  "165882770520240319.pdf": {
    "Member Name": "Shawna Hutchings",
    DOB: "09/21/1979",
    " Member ID": "100000397767",
    "Clinic Name": "Unify Community Health",
    "PCP Name": "Natalie McPherson",
    "PCP address": "120 W Mission",
    City: "Spokane",
    State: "WA",
    ZIP: "99201",
  },
  "198485391920240319.pdf": {
    "Member Name": "Henry Berg",
    DOB: "1/17/2024",
    " Member ID": "110068765125",
    "Clinic Name": "The Doctors Clinic",
    "PCP Name": "Dr. Gregory Hoisington",
    "PCP address": "1780 NW Myhre Rd #2120",
    City: "Silverdale",
    State: "WA",
    ZIP: "98383",
  },
  "Tenoudji 91213 0524.pdf": {
    Segment: "Third Party",
    "New Rx's": "439",
    "Refill Rx's": "797",
    "Total Cost": "$110075.34",
    "Total Price": "$72725.64",
  },
  "FX037042.pdf": {
    Segment: "PREPAID",
    "New Rx's": "Not applicable",
    "Refill Rx's": "Not applicable",
    "Total Cost": "507.77",
    "Total Price": "507.77",
  },
  "PBMCase1.pdf":{
    "Date of Birth": "1/1/1991",
    "Pharmacy Name": "Rite Aid #053213",
    "Pharmacy NPI": 1871602112,
    "Prescriber": "Lisa A",
    "Prescriber NPI": 1231231231,
    "Medication and Strength": "Lidocaine PTCH 5%",
    "Qty/Days Supply": 10,
    "Diagnosis code": "M25.511"
  },
  "PBMCase2.pdf":{
    "Date of Birth": "1/1/1973",
    "Pharmacy Name": "Rite Aid #053985",
    "Pharmacy NPI": 1871602231,
    "Prescriber": "Homer S",
    "Prescriber NPI": 1231231345,
    "Medication and Strength": "Cibinqo 100MG OR TABS",
    "Qty/Days Supply": 30,
    "Diagnosis code": "L20.89",
  },
  "PBMCase3.pdf":{
    "Date of Birth": "1/1/1994",
    "Pharmacy Name": "Rite Aid #053000",
    "Pharmacy NPI": 1871602001,
    "Prescriber": "Bartholomeu A",
    "Prescriber NPI": 1231231999,
    "Medication and Strength": "Retin-A 0.05% EX CREA",
    "Qty/Days Supply": 20,
    "Diagnosis code": "L70.0"
  },
  "PBMCase4.pdf":{
    "Date of Birth": "1/1/1978",
    "Pharmacy Name": "Rite Aid #053998",
    "Pharmacy NPI": 1871602043,
    "Prescriber": "Maggie A",
    "Prescriber NPI": 1231231945,
    "Medication and Strength": "Botox SOLR 200 UNIT",
    "Qty/Days Supply": "Not provided",
    "Diagnosis code": "G43.709"    
  },
  "case1.pdf":{
    "Date of Birth": "04/11/1978",
    "Patient": "Ethan Parker",
    "MemberID": 4567839021,
    "Diagnosis Code": "E11.9",
    "Diagnosis Description": "Type 2 Diabetes Mellitus without complications",
    "Medication": "Mounjaro",
    "Strength": "60 mg per 28 days",
    "Pharmacy Name": "GoodHealth Pharmacy #5432",
    "Pharmacy Address": "100 Wellness Ave, Oakwood, TX 75103",
    "Contact Name": "Benjamin Cole",
    "Pharmacy NPI": "1427567893",
    "FaxNo": "325-123-9846",
    "Billing TIN": "234567890",
    "Prescriber Name": "Rebecca Chen, MD",
    "Prescriber Address": "234 Lakeview Road, Oakwood, TX 75104",
    "Prescriber NPI": "9876543210",
    "Prescriber FaxNo": "325-467-1921",
    "Servicing TIN": "765432109"
  },
  "case4.pdf":{
    "Date of Birth": "05/17/1963",
    "Patient": "Henry Morgan",
    "Member ID": "1029384756",
    "Diagnosis Code & Description": "E11.9 - Type 2 Diabetes Mellitus without complications",
    "Medication & Strength": "Ozempic",
    "Qty/Days Supply": "8 mg per 28 days",
    "Pharmacy Name": "Healthwise Pharmacy #2764",
    "Address": "678 Maple Street, Riverton, NY 12345",
    "Contact Name": "Lisa Hamilton",
    "Pharmacy NPI": "1847392016",
    "Phone No": "518-234-5678",
    "Fax No": "518-234-5679",
    "Billing TIN": "876543210",
    "Prescriber Name": "Anthony Reed, MD",
    "Prescriber NPI": "1234567891",
    "Servicing TIN": "456789012"
  },
  "case3.pdf":{
    "Date of Birth": "07/09/1971",
    "Patient": "Lucas Thompson",
    "Member ID": "9123458760",
    "Diagnosis Code & Description": "E11.9 - Type 2 Diabetes Mellitus without complications",
    "Medication & Strength": "Ozempic",
    "Qty/Days Supply": "8 mg per 28 days",
    "Pharmacy Name": "HealthFirst Pharmacy #1423",
    "Address": "1200 Cedar Avenue, Downtown City, CA 94103",
    "Contact Name": "415-789-5678",
    "Pharmacy NPI": "1845621075",
    "Phone No": "415-789-1234",
    "Fax No": "415-789-5678",
    "Billing TIN": "876543210",
    "Prescriber Name": "Karen Ford, MD",
    "Prescriber Address": "789 Medical Plaza, Downtown City, CA 94104",
    "Prescriber NPI": "3498761230",
    "Prescriber Phone No": "415-789-3456",
    "Prescriber Fax No": "415-789-3457",
    "Servicing TIN": "567890123"
  },

  "case2.pdf":{
    "Date of Birth": "02/15/1965",
    "Patient": "Jason Roberts",
    "MemberID": "1234567890",
    "DiagnosisCode": "E11.9",
    "DiagnosisDescription": "Type 2 Diabetes Mellitus without complications",
    "Medication": "Mounjaro",
    "Strength": "60 mg per 28 days",
    "PharmacyName": "CarePlus Pharmacy #7890",
    "PharmacyAddress": "543 Wellness Way, New Town, NY 11234",
    "ContactName": "Anna Marshall",
    "PharmacyNPI": "1547392012",
    "FaxNo": "718-123-4568",
    "BillingTIN": "123456789",
    "PrescriberName": "Michael Young, MD",
    "PrescriberAddress": "101 Medical Blvd, New Town, NY 11235",
    "PrescriberNPI": "1236547890",
    "PrescriberFaxNo": "718-789-1235",
    "ServicingTIN": "234567890"
  }, 
  "1ccb3072-cfdd-4898-a082-c891e27d01f0.pdf":{
    "Date of Birth": "01/01/1991",
    "Patient": "Jakob Oram",
    "MemberID": "A15000000132",
    "Diagnosis Code 1": "E78.5",
    "Diagnosis Description 1": "Hyperlipidemia, unspecified",
    "Diagnosis Code 2": "R73.05",
    "Diagnosis Description 2": "Prediabetes",
    "Diagnosis Code 3": "I10.0",
    "Diagnosis Description 3": "Essential (primary) hypertension",
    "Diagnosis Code 4": "E66.9",
    "Diagnosis Description 4": "Obesity, unspecified",
    "Medication": "Saxenda (liraglutide)",
    "Strength": "3 mg per 30 days",
  }, 
  "bd36a42d-c246-4651-a891-32842db87d0a.pdf":{
    "Date of Birth": "05/18/1969",
    "Patient": "Joe Kerry",
    "MemberID": "A15000000132 ",
    "Diagnosis Code 1": "E78.2",
    "Diagnosis Description 1": "Mixed hyperlipidemia",
    "Diagnosis Code 2": "Z00.00",
    "Diagnosis Description 2": "Healthcare maintenance",
    "Medication": "Ozempic (semaglutide)",
    "Strength": " 0.25 mg (2 mg/3 mL) per 56 days",
  },
  "a00dd979-9a14-4fe4-a041-5d50a948cdb7.pdf":{
    "Date of Birth": "12/10/1994",
    "Patient": "Joe Kerry",
    "MemberID": "A15000000132",
    "Diagnosis Code 1": "R07.0",
    "Diagnosis Description 1": "Pain in throat",
    "Diagnosis Code 2": "H92.01",
    "Diagnosis Description 2": "Ear pain, right ear",
    "Medication": "Ozempic (semaglutide)",
    "Strength": "0.25 mg (2 mg/3 mL) per 28 days",
  },
  "6a629916-3d48-4421-8164-a61fa6941b19.pdf":{
    "Date of Birth": "6/15/1969",
    "Patient": "Jakob Oram",
    "MemberID": " A15000000132",
    "Diagnosis Code 1": "E66.01",
    "Diagnosis Description 1": "Morbid (severe) obesity due to excess calories ",
    "Diagnosis Code 2": "R73.03",
    "Diagnosis Description 2": "Prediabetes",
    "Medication": "Ozempic (semaglutide)",
    "Strength": " 0.25 mg (2 mg/3 mL) per 28 days",
  },
  "6a597cd0-d738-4106-af21-65a8d39b9a66.pdf":{
    "Date of Birth": "02/19/1972",
    "Patient": "Ema Joseph",
    "MemberID": "A15000000132",
    "Diagnosis Code": "E66.01",
    "Diagnosis Description": "Morbid (severe) obesity due to excess calories",
    "Medication": "Zepbound (tirzepatide)",
    "Strength": "2.5 mg per 28 days",
  },
  "2d92ab6b-229a-409e-a26f-e231e39b5f18.pdf":{
    "Date of Birth": "10/10/1990",
    "Patient": "Emily Carter",
    "MemberID": "B15000000987",
    "Diagnosis Code 1": "E66.01",
    "Diagnosis Description 1": "Morbid (severe) obesity due to excess calories",
    "Diagnosis Code 2": "Z68.41",
    "Diagnosis Description 2": "Body	mass	index	[BMI]	40.0-44.9,	adult",
    "Diagnosis Code 3": "Z71.3",
    "Diagnosis Description 3": "Dietary	counseling	and	surveillance",
    "Diagnosis Code 4": "B37.31",
    "Diagnosis Description 4": "Acute	candidiasis	of	vulva	and	vagina",
    "Medication": "Saxenda (liraglutide)",
    "Strength": "3 mg per 30 days",
  }, 
  "f246d296-9b1e-4ea7-887d-e91839f0b80f.pdf":{
    "Date of Birth": "01/01/1991",
    "Patient": "Jakob Oram",
    "MemberID": "A15000000132",
    "Diagnosis Code 1": "E78.5",
    "Diagnosis Description 1": "Hyperlipidemia, unspecified",
    "Diagnosis Code 2": "R73.05",
    "Diagnosis Description 2": "Prediabetes",
    "Diagnosis Code 3": "I10",
    "Diagnosis Description 3": "Essential (primary) hypertension",
    "Diagnosis Code 4": "E66.9",
    "Diagnosis Description 4": "Obesity, unspecified",
    "Medication": "Saxenda (liraglutide)",
    "Strength": "3 mg per 30 days",
  }, 
};

export const nestedValues: any = {
  "Billing Document - 8007307259.pdf": {
    "Supplier Name": "Stericycle, Inc.",
    "Supplier Address": "28883 Network Place, Chicago, IL 60673-1288",
    "Invoice Number": "8007307259",
    "Invoice Date": "06-03-2024",
    "Invoice Due Date": "07-03-2024",
    "Payment Terms": "Net due in 30 days",
    "Purchase Order (PO) Number": "8164528034",
    "Line Item Information": {
      "Item Number": "Not specified",
      Description: "REGULAR SERVICE | ON-SITE (PAPER), CONSOLE (STANDARD)",
      "Number of Items": 4,
      "Unit Price": 164.43,
      "Total Price": 164.43,
      Tax: 0.0,
      Freight: 0.0,
      "Recycling Recovery Surcharge": 22.86,
      "Fuel Surcharge": 27.95,
      "Environmental Surcharge": 6.58,
    },
    "Total Amount Due": 221.82,
    "Currency Type": "USD",
  },
  "Inv_31289_from_SILICONES_PLUS_INC._1412.pdf": {
    "Supplier Name": "Silicones Plus Inc.",
    "Supplier Address": "C/O: Amegy-Lockbox, Dept. 8147, PO BOX 650002, Dallas, TX. 75265-0002",
    "Invoice Number": "31289",
    "Invoice Date": "6/5/2024",
    "Invoice Due Date": "7/5/2024",
    "Payment Terms": "NET30",
    "Purchase Order (PO) Number": "130126",
    "Line Item Information": {
      "Item Number": "712477149097 (P/N 30-4880)",
      Description: "BOSEXIL (5KG/NET/CONTAINER)",
      "Number of Items": "1 container (5 units)",
      "Unit Price": 686.4,
      "Total Price": 3432.0,
      Tax: "Not specified",
      Freight: "Not specified (Ship via FED EX GROUND, F.O.B. ORIGIN/COL.)",
    },
    "Total Amount Due": 3432.0,
    "Currency Type": "USD",
  },
  "Inv_65375_from_Exakt_Technologies_Inc_10832.pdf": {
    "Supplier Name": "Exakt Technologies Inc.",
    "Supplier Address": "13501 Railway Dr., Oklahoma City, OK 73114",
    "Invoice Number": "65375",
    "Invoice Date": "6/6/2024",
    "Invoice Due Date": "7/6/2024",
    "Payment Terms": "Net 30",
    "Purchase Order (PO) Number": "130996",
    "Line Item Information": {
      "Item Number": "22300",
      Description: "Plastic Guide for E50 EC",
      "Number of Items": 2,
      "Unit Price": 179.2,
      "Total Price": 179.2,
      Tax: 0.0,
      Freight: "Not specified (Ship via Cust FedEx)",
    },
    "Total Amount Due": 179.2,
    "Currency Type": "USD",
  },
  "Invoice-2024-83163-00.pdf": {
    "Supplier Name": "Reliance Vitamin LLC",
    "Supplier Address": "3775 Park Avenue, Edison, NJ 08820",
    "Invoice Number": "2024-83163-00",
    "Invoice Date": "06/03/2024",
    "Invoice Due Date": "07/06/2024",
    "Payment Terms": "2% 10-Net 30",
    "Purchase Order (PO) Number": "131031",
    "Line Item Information": [
      {
        "Part Number": "1182-120",
        Description: "Prenatal Plus Tabs 120's",
        "Number of Items": 36,
        "Unit Price": 9.71,
        "Total Price": 349.65,
      },
      {
        "Part Number": "2252-60",
        Description: "Adrenal Support Vcaps 60's",
        "Number of Items": 192,
        "Unit Price": 10.84,
        "Total Price": 2080.8,
      },
      {
        "Part Number": "3251-100",
        Description: "Vitamin B6 100Mg Tabs 100's",
        "Number of Items": 24,
        "Unit Price": 3.83,
        "Total Price": 91.8,
      },
      {
        "Part Number": "4441-100",
        Description: "C 1000 With 100Mg Bio Caps 100's",
        "Number of Items": 192,
        "Unit Price": 7.28,
        "Total Price": 1396.8,
      },
      {
        "Part Number": "5341-30",
        Description: "Phosphatidyl Serine 500Mg Softgels 30's",
        "Number of Items": 180,
        "Unit Price": 9.3,
        "Total Price": 1674.0,
      },
      {
        "Part Number": "6781-60",
        Description: "Ultra Veggie Enzymes Vcaps 60's",
        "Number of Items": 60,
        "Unit Price": 8.21,
        "Total Price": 492.75,
      },
      {
        "Part Number": "6942-60",
        Description: "Nac 600 Plus Caps 60's",
        "Number of Items": 72,
        "Unit Price": 7.28,
        "Total Price": 524.16,
      },
      {
        "Part Number": "8511-100",
        Description: "Omega-3 Softgel 100's",
        "Number of Items": 36,
        "Unit Price": 4.54,
        "Total Price": 163.35,
      },
      {
        "Part Number": "8822-60",
        Description: "Brain Support Vcaps 60's",
        "Number of Items": 36,
        "Unit Price": 11.21,
        "Total Price": 403.65,
      },
      {
        "Part Number": "9111-90",
        Description: "All Season Support Tabs 90's",
        "Number of Items": 24,
        "Unit Price": 14.21,
        "Total Price": 341.1,
      },
    ],
    Tax: "Not specified",
    Freight: "Prepaid and Add (Estes)",
    "Total Amount Due": 7518.06,
    "Currency Type": "USD",
  },
}

export const extractValuesSummary: SummaryData = {
  "Enrolee Info.pdf": `General Summary:<br>
  The medications include Vraylar oral capsule 1.5 mg, venlafaxine ER 150 mg capsule (extended release 24 hr), and venlafaxine ER 37.5 mg capsule (extended release 24 hr). The instructions for Vraylar are to take 1 capsule (1.5 mg) orally once daily in the afternoon. The prescription was given on 12/14/2022. The instructions for venlafaxine ER 150 mg capsule are to take 1 capsule (150 mg) orally once daily in the afternoon with food. This prescription was refilled on 12/14/2022. The venlafaxine ER 37.5 mg capsule was replaced with Vraylar 1.5 mg capsule once daily in the afternoon with venlafaxine ER 187.5 mg for bipolar depression.<br><br>
  The future plan of care discussed with the patient includes the possibility of switching to Caplyta or lithium carbonate if she is unable to tolerate Vraylar. The phone number provided for contact is (509) 339-2894, and the fax number is (509) 886-7484. The prescription for Vraylar is for 30 capsules with 0 refills.<br><br>
  In addition to the medication plan, there is a discussion about the insurance coverage and formulary. Caplyta is mentioned as a non-preferred drug that requires prior authorization, while Vraylar is a preferred drug that also requires prior authorization. Latuda is mentioned as another atypical antipsychotic medication that may require prior authorization or step therapy before Vraylar. The next follow-up visit with the NP is scheduled in 4 weeks, with the option for an earlier visit if necessary. The prescriptions for Vraylar and venlafaxine ER were sent to Rite Aid #05319 in Richland, WA, as per patient's request.<br><br>
  The progress note also mentions the discontinuation of Latuda due to unsatisfactory treatment response for bipolar depression. It was replaced with Vraylar 1.5 mg capsule once daily. Patient's risk for self-harm or harm to others is assessed as low, although her chronic risk is moderately elevated due to static and dynamic risk factors. She is aware of emergency procedures and can access emergency care if necessary.<br><br>
  The progress note also includes information about other medications prescribed to Patient, such as Sulfur 2% Cleanser and Tretinoin (Retinol A) Cream 0.025% for acne, and Ubrelvy 100 mg oral tablet for migraine headaches.<br><br>
  The prior authorization request for Vraylar is mentioned, and more information is needed to review the request. The dosage strengths of Vraylar are listed as 20 mg, 40 mg, 60 mg, 80 mg, and 120 mg tablets, and it must be taken with at least 350 calories, usually with dinner or supper.`,
  
  "PBMCase1.pdf":`General Summary:<br>
    • COB: N<br>
    • Requesting: Lidocaine 5% EX PTCH Place 1 patch onto the skin every 12 (twelve) hours, on for 12 hours and off for 12 hours in any 24 hour period 10/10 days<br>
    • Diagnosis: M25.551-Pain in right hip G89.29-Other chronic pain<br>
    • Member PA History: This is an initial request, brand new start.<br>
    • PA Type/Indicator: PDLP PA<br>
    • Reject Message: 75 PA Required<br>
    • Specialty Drug List: No<br>
    • Provider FCI: Provider did not document any med trials<br>
    • Claim History: Meloxicam 15 MG 11/28/2023<br>
    • Notes: MD notes sent dated 12/26/2023 Devon is here today with increased hip pain.<br>
    She has cerebral palsy. This pain has progressively gotten more painful over last 6 months. Recently,<br>
    especially her right hip, has been "popping out".Chronic right hip pain lidocaine (LIDODERM) 5 %;<br>
    Place 1 patch onto the skin every 12 (twelve) hours. On for 12 hours, off for 12 hours in any 24 hour<br>
    period. ibuprofen (MOTRIN) 600 mg tablet; Take 1 tablet (600 mg total) by mouth every 8 (eight) hours<br>
    as needed for Pain for up to 10 days.<br>
    - Ambulatory Referral to Sports Medicine`,

  "PBMCase2.pdf":`General Summary:• COB: N<br>
    • Requesting: Cibinqo 100MG OR TABS, #30/30ds, SIG: Pg 2 CN 12/11/23)<br>
    • Diagnosis: L20.89-Other atopic dermatitis<br>
    • Member PA History: This is an initial request, but the member is currently taking the med (Cibinqo 200mg Samples given to patient (pg 1 CN)).<br>
    • PA Type/Indicator: NF<br>
    • Reject Message: MR PRODUCT NOT ON FORMULARY<br>
    • Specialty Drug List: No<br>
    • Provider FCI: Provider did not document any med trials<br>
    • Claim History: no paid claims for Cibinqo or any other related meds<br>
    • Notes: 50yo male with atopic dermatitis, Pt is almost completely clear today after taking the 2 week sample of 200mg Cibinqo - I did provide him with more samples but decreased to 100mg daily (pg 2 CN)`,

  "PBMCase3.pdf":`General Summary:<br>
      • COB: N<br>
      • Requesting: Retin-A 0.05% EX CREAM: 20/30ds:SIG: apply a pea size amount to affeceted area at<br>
      bedtime, CN pg 1<br>
      • Diagnosis: L70.0-Acne vulgaris<br>
      • Member PA History: This is an initial request, brand new start.<br>
      • PA Type/Indicator: Preferred AGE (Max 26 Years)<br>
      • Reject Message: 60 DrugNotCvrd forPatientAge Maximum Patient Age of 26 76 Plan Limitations<br>
      Exceeded<br>
      • Specialty Drug List: No<br>
      • Provider FCI: Per Chart notes, Pg 3, adapelene, BPO, Doxy<br>
      • Claim History: No paid claims for Retin-A, mupirocin 1/25/24<br>
      • Notes: Chart notes 1/25/24, pg 1, mupirocin and Retin-A. PG 3, on face`,

  "PBMCase4.pdf":`General Summary:<br>
  • MCP Agents: N<br>
  • COB: N<br>
  • Requesting: Botox SOLR 200UNIT<br>
  qty 1/84 days Sig: not provided<br>
  • Diagnosis: G43.709-Chronic migraine w/o aura, not intractable, w/o stat migr • Member PA History: Medication was prevously denied on 10/13/2023<br>
  • PA Type/Indicator: NF<br>
  • Reject Message: MR Non-Formulary Drug<br>
  • Specialty Drug List: N<br>
  • Provider FCI: Per pa form page 4, member has tried and failed Amitriptyline, qulipta, and depakote. Per cn page 11, member tried and failed xoloft, gabapentin, and baclofen.<br>
  • Claim History: No claims for preferred drug trials<br>
  •Notes:<br>
  Chart notes and labs from 09/06/2023.<br>
  The Provider Specialty is neurologist.<br>
  Medication is FDA indicated.<br>
  Per criteria, Request for COT ;Pend to RPH for review.`,
  "case1.pdf":`Patient's Medical History Pertaining to Mounjaro (Tirzepatide):<br>

- Patient Details:<br>
  - Name: Ethan Parker<br>
  - Age: 45 years<br>
  - Diagnosis: Type 2 Diabetes Mellitus (T2DM) without complications<br>

- Medical Background:<br>
  - Ethan has been managing Type 2 diabetes for over 10 years.<br>
  - He has experienced persistent hyperglycemia, fatigue, and weight gain, which have complicated his diabetes management.<br>
  - His HbA1c levels have consistently been above target, recently measured at 8.3%, indicating inadequate glycemic control despite adherence to medication.<br>

- Previous Diabetes Medications and Responses:<br>
  - Metformin: Discontinued due to gastrointestinal side effects and suboptimal control of blood sugar.<br>
  - SGLT2 Inhibitor (Canagliflozin): Initially effective but discontinued due to recurrent urinary tract infections and decreased efficacy.<br>

  - GLP-1 Receptor Agonist (Semaglutide): Discontinued due to nausea and minimal impact on HbA1c reduction.<br>

- Request for Mounjaro (Tirzepatide):<br>
  - Ethan's endocrinologist recommends initiating Mounjaro for better blood sugar management.<br>
  - Recent lab results suggest that his diabetes is worsening despite lifestyle modifications and previous medications.<br>
  - The decision to try Mounjaro is based on its potential to provide better glycemic control with a once-weekly injection.<br>

- Anticipated Benefits:<br>
  - Weight loss potential, improved glycemic control, and a reduction in HbA1c.<br>

- Insurance and PA Status:<br>
  - The insurance has been contacted, and Mounjaro is currently not on the formulary.<br>
  - A prior authorization request is required for coverage.<br>

- Provider's Office Notes:<br>
  - A detailed letter was submitted by Ethan's primary care physician outlining the medical necessity for Mounjaro.<br>

- Next Steps:<br>
  - Pending insurance approval, initiate Mounjaro as a part of Ethan's diabetes management plan.<br>`,
  "case4.pdf":`Patient's Medical History Pertaining to Ozempic Use:<br>\n
- Diagnosis and Chief Complaint:<br>\n
  - Henry Morgan has been diagnosed with Type 2 Diabetes Mellitus (T2DM) for 15 years, with ongoing challenges in achieving optimal glycemic control.<br>\n
  - He presents with cardiovascular risk factors including hypertension, obesity, dyslipidemia, and a family history of premature ASCVD.<br>\n
  - Despite adherence to previous treatments, his recent HbA1c levels have remained above target, indicating suboptimal control of his diabetes.<br>\n
  - He experiences fatigue, progressive weight gain, and intermittent chest discomfort with exertion.<br>\n
<br>\n
- Past Medical History:<br>\n
  - Type 2 Diabetes Mellitus diagnosed 15 years ago.<br>\n
  - Atherosclerotic Cardiovascular Disease (ASCVD) including confirmed coronary artery disease with a myocardial infarction two years ago, stable angina, and dyslipidemia.<br>\n
  - Hypertension managed with antihypertensive therapy.<br>\n
  - Dyslipidemia managed with atorvastatin.<br>\n
  - Metabolic Syndrome contributing to increased cardiovascular risk.<br>\n
  - Chronic Kidney Disease (CKD) Stage 2, with stable renal function monitored biannually.<br>\n
<br>\n
- Medication History Related to Diabetes:<br>\n
  - Previous medications include Metformin (discontinued due to gastrointestinal side effects), Dapagliflozin (SGLT2 inhibitor, discontinued due to recurrent urinary tract infections), and Liraglutide (GLP-1 agonist, discontinued due to nausea and limited effect on HbA1c).<br>\n
  - Currently prescribed Ozempic (semaglutide) at a dosage of 8 mg per 28 days to manage blood glucose levels and address cardiovascular risks.<br>\n
<br>\n
- Reason for Ozempic Prescription:<br>\n
  - Ozempic was recommended due to its potential benefits in improving glycemic control and providing cardiovascular benefits, especially after the failure of previous medications to achieve desired HbA1c levels.<br>\n
  - The decision to initiate Ozempic therapy was based on Henry's elevated HbA1c, his diagnosis of diabetes, and associated cardiovascular risk factors.<br>\n
<br>\n
- Plan and Monitoring:<br>\n
  - Initiate Ozempic therapy with a goal to improve glycemic control, reduce HbA1c, and provide cardiovascular benefits.<br>\n
  - Scheduled follow-up every 12 weeks to evaluate therapeutic response and monitor potential side effects.<br>\n
  - Routine lab monitoring including HbA1c, fasting glucose, liver function tests, and lipid panel every 3 months to assess therapy response and adjust dosage if needed.<br>\n
<br>\n
- Patient Education and Counseling:<br>\n
  - Henry received education on Ozempic's potential benefits and side effects, such as gastrointestinal discomfort.<br>\n
  - He was provided with instruction on subcutaneous injection technique to minimize injection site reactions.<br>\n
`,
"case3.pdf":`Patient's Medical History Pertaining to Ozempic:<br>\n
- Patient Details:<br>\n
  - Name: Lucas Thompson<br>\n
  - Age: 52 years<br>\n
  - Diagnosis: Type 2 Diabetes Mellitus (T2DM) without complications<br>\n
<br>\n
- Medical Background:<br>\n
  - Diagnosed with Type 2 Diabetes Mellitus 12 years ago.<br>\n
  - History of inadequate glycemic control despite various treatments.<br>\n
  - Previous medications include metformin, SGLT2 inhibitor (dapagliflozin), and GLP-1 receptor agonist (exenatide), all discontinued due to side effects or limited efficacy.<br>\n
<br>\n
- Current Symptoms Related to Diabetes:<br>\n
  - Persistent hyperglycemia with fasting glucose levels between 160–210 mg/dL.<br>\n
  - Recent HbA1c reading of 8.1%, indicating uncontrolled diabetes.<br>\n
  - Weight gain of approximately 15 pounds over the past year, contributing to increased insulin resistance.<br>\n
  - Experiences fatigue, impacting daily productivity and physical activity.<br>\n
  - Occasional episodes of blurred vision, particularly during hyperglycemic episodes.<br>\n
<br>\n
- Ozempic (Semaglutide) Therapy:<br>\n
  - Initiated due to failure of previous glycemic therapies and ongoing uncontrolled diabetes.<br>\n
  - Dosage: 8 mg per 28 days.<br>\n
  - Goals of therapy include improving glycemic control, reducing HbA1c, and assisting with weight management.<br>\n
<br>\n
- Monitoring and Follow-Up:<br>\n
  - Regular follow-ups scheduled every 12 weeks to evaluate therapeutic response and monitor for side effects.<br>\n
  - Routine lab monitoring including HbA1c, fasting glucose, liver function tests, and lipid panel every 3 months.<br>\n
  - Patient Education and Counseling:<br>\n
  - Reviewed potential benefits and side effects of Ozempic, including gastrointestinal symptoms.<br>\n
  - Provided guidance on self-administration and injection technique to minimize site reactions.<br>\n
  - Discussed possible side effects such as nausea and injection site discomfort.<br>\n
<br>\n
- Lifestyle and Management Counseling:<br>\n
  - Referral to a dietitian for dietary support to aid in weight management.<br>\n
  - Recommended light activity to gradually increase physical fitness and improve glycemic control.<br>\n
<br>\n
This comprehensive overview of Lucas Thompson's medical history related to Ozempic therapy outlines his diabetic condition, previous treatment failures, current medication plan, and supportive measures to manage his Type 2 Diabetes effectively.<br>\n
`,
"case2.pdf":`Based on the provided medical history, here is a comprehensive summary of the patient's history related to the drug Mounjaro (tirzepatide):<br>\n
- Patient Background: Jason Roberts, a 58-year-old male with a longstanding history of Type 2 Diabetes Mellitus (T2DM), diagnosed 15 years ago. He has struggled with consistent glycemic control, leading to persistent hyperglycemia, weight gain, and fatigue.<br>\n
<br>\n
- Previous Medications and Responses:<br>\n
  - Metformin: Discontinued due to gastrointestinal upset which limited adherence and efficacy.<br>\n
  - SGLT2 Inhibitor (empagliflozin): Initially effective but later led to urinary tract infections and was ineffective in maintaining glycemic control.<br>\n
  - GLP-1 Receptor Agonist (exenatide): Caused intolerable nausea and gastrointestinal discomfort, leading to discontinuation.<br>\n
<br>\n
- Current Medical Issues:<br>\n
  - Type 2 Diabetes Mellitus: Chronic hyperglycemia with recent HbA1c reading of 8.5%, indicating poor control.<br>\n
  - Obesity: BMI of 34.5 kg/m², contributing to insulin resistance.<br>\n
  - Non-Alcoholic Fatty Liver Disease (NAFLD): Diagnosed due to elevated liver enzymes, likely related to obesity and diabetes.<br>\n
- Reason for Mounjaro Prescription:<br>\n
  - Due to the failure of previous diabetes medications and the need for improved glycemic control, Mounjaro was recommended. It is intended to help manage his diabetes more effectively and achieve lower HbA1c levels.<br>\n
<br>\n
- Mounjaro Therapy Details:<br>\n
  - Dosage: 60 mg per 28 days.<br>\n
  - Goals: Improve glycemic control, reduce HbA1c, and address insulin resistance.<br>\n
  - Monitoring: Follow-ups scheduled every 12 weeks to evaluate response and monitor side effects.<br>\n
<br>\n
- Additional Management Plans:<br>\n
  - Patient Education: Reviewed benefits of Mounjaro, including potential for HbA1c reduction and weight management. Provided guidance on injection technique and discussed possible side effects.<br>\n
  - Lifestyle and Weight Management Support: Referred to a dietitian and encouraged gradual physical activity.<br>\n
  - Routine Lab Monitoring: To assess therapy efficacy and adjust as needed, including HbA1c, fasting glucose, liver function tests, and lipid panel every 3 months.<br>\n
<br>\n
This summary encapsulates the patient's medical history and current plan regarding the use of Mounjaro to manage his Type 2 Diabetes Mellitus effectively.<br>\n
`,
  "1ccb3072-cfdd-4898-a082-c891e27d01f0.pdf":`Based on the provided medical history, here is a comprehensive summary of the patient's history related to the drug Mounjaro (tirzepatide):<br>\n`,
  "bd36a42d-c246-4651-a891-32842db87d0a.pdf":`Based on the provided medical history, here is a comprehensive summary of the patient's history related to the drug Mounjaro (tirzepatide):<br>\n`,
  "a00dd979-9a14-4fe4-a041-5d50a948cdb7.pdf":`Based on the provided medical history, here is a comprehensive summary of the patient's history related to the drug Mounjaro (tirzepatide):<br>\n`,
  "6a629916-3d48-4421-8164-a61fa6941b19.pdf":`Based on the provided medical history, here is a comprehensive summary of the patient's history related to the drug Mounjaro (tirzepatide):<br>\n`,
  "6a597cd0-d738-4106-af21-65a8d39b9a66.pdf":`Based on the provided medical history, here is a comprehensive summary of the patient's history related to the drug Mounjaro (tirzepatide):<br>\n`,
  "2d92ab6b-229a-409e-a26f-e231e39b5f18.pdf":`Based on the provided medical history, here is a comprehensive summary of the patient's history related to the drug Mounjaro (tirzepatide):<br>\n`,
  "f246d296-9b1e-4ea7-887d-e91839f0b80f.pdf":`Patient's Medical History Pertaining to Mounjaro (Tirzepatide):<br>

- Patient Details:<br>
  - Name: Ethan Parker<br>
  - Age: 45 years<br>
  - Diagnosis: Type 2 Diabetes Mellitus (T2DM) without complications<br>

- Medical Background:<br>
  - Ethan has been managing Type 2 diabetes for over 10 years.<br>
  - He has experienced persistent hyperglycemia, fatigue, and weight gain, which have complicated his diabetes management.<br>
  - His HbA1c levels have consistently been above target, recently measured at 8.3%, indicating inadequate glycemic control despite adherence to medication.<br>

- Previous Diabetes Medications and Responses:<br>
  - Metformin: Discontinued due to gastrointestinal side effects and suboptimal control of blood sugar.<br>
  - SGLT2 Inhibitor (Canagliflozin): Initially effective but discontinued due to recurrent urinary tract infections and decreased efficacy.<br>

  - GLP-1 Receptor Agonist (Semaglutide): Discontinued due to nausea and minimal impact on HbA1c reduction.<br>

- Request for Mounjaro (Tirzepatide):<br>
  - Ethan's endocrinologist recommends initiating Mounjaro for better blood sugar management.<br>
  - Recent lab results suggest that his diabetes is worsening despite lifestyle modifications and previous medications.<br>
  - The decision to try Mounjaro is based on its potential to provide better glycemic control with a once-weekly injection.<br>

- Anticipated Benefits:<br>
  - Weight loss potential, improved glycemic control, and a reduction in HbA1c.<br>

- Insurance and PA Status:<br>
  - The insurance has been contacted, and Mounjaro is currently not on the formulary.<br>
  - A prior authorization request is required for coverage.<br>

- Provider's Office Notes:<br>
  - A detailed letter was submitted by Ethan's primary care physician outlining the medical necessity for Mounjaro.<br>

- Next Steps:<br>
  - Pending insurance approval, initiate Mounjaro as a part of Ethan's diabetes management plan.<br>`
};


export const shortFormSummary: SummaryData = {
  "case1.pdf": `
- Patient Overview:<br />
  Name: Ethan Parker<br />
  Condition: Type 2 Diabetes Mellitus (T2DM)<br />
  Duration: Over 10 years<br />
  Current Challenge: Persistent hyperglycemia, unstable blood sugar levels<br /><br />

- Previous Therapies and Responses:<br />
  - Metformin: Discontinued due to gastrointestinal issues and inadequate blood sugar control.<br />
  - SGLT2 Inhibitor (canagliflozin): Stopped because of recurrent urinary infections and reduced effectiveness.<br />
  - GLP-1 Receptor Agonist (semaglutide): Ceased due to nausea and minimal HbA1c reduction.<br /><br />

- Current Treatment Plan:<br />
  - Medication: Mounjaro (tirzepatide)<br />
  - Dosage: 60 mg every 28 days<br />
  - Goals: Improve blood glucose management and support weight control<br /><br />

- Monitoring and Follow-Up:<br />
  - Frequency: Every 12 weeks for clinical assessment; every 3 months for lab tests (HbA1c, fasting glucose, liver function, lipid panel)<br /><br />

- Patient Education and Counseling:<br />
  - Topics Covered: Benefits, potential side effects of Mounjaro, injection techniques, infection prevention<br />
  - Objective: Enhance quality of life and stabilize metabolic health<br />
`,
  "case4.pdf": `
- Name: Henry Morgan<br />
- Condition: Type 2 Diabetes Mellitus (T2DM), diagnosed 15 years ago<br />
- Other Health Issues: Hypertension, obesity, dyslipidemia, ASCVD, stable angina, myocardial infarction (2 years ago), metabolic syndrome, CKD Stage 2<br /><br />

- Current Medication:<br />
  - Ozempic (Semaglutide): 8 mg per 28 days for blood glucose control and cardiovascular risk management<br /><br />

- Previous Medications for Diabetes:<br />
  - Metformin, Dapagliflozin, Liraglutide (discontinued due to side effects and limited efficacy)<br /><br />

- Reason for Ozempic Prescription:<br />
  - To improve glycemic control and provide cardiovascular benefits after other medications failed to achieve target HbA1c levels.<br /><br />

- Monitoring and Follow-Up:<br />
  - Quarterly follow-ups and routine lab tests (HbA1c, fasting glucose, liver function, lipid panel) every 3 months<br /><br />

- Patient Education:<br />
  - Informed about Ozempic's benefits and potential side effects, and trained in subcutaneous injection technique.<br />
`,
  "case3.pdf": `
- Patient Overview:<br />
  Name: Lucas Thompson<br />
  Age: 52<br />
  Condition: Type 2 Diabetes Mellitus (T2DM), diagnosed 12 years ago, currently uncontrolled.<br /><br />

- Medical History:<br />
  - Previous Treatments: Metformin, dapagliflozin, exenatide (discontinued due to side effects/inefficacy).<br />
  - Current Symptoms: Persistent hyperglycemia, weight gain, fatigue, occasional blurred vision.<br /><br />

- Current Treatment:<br />
  - Medication: Ozempic (Semaglutide)<br />
  - Dosage: 8 mg per 28 days<br />
  - Goals: Improve glycemic control, reduce HbA1c, assist with weight management.<br /><br />

- Monitoring and Follow-Up:<br />
  - Frequency: Every 12 weeks<br />
  - Tests: HbA1c, fasting glucose, liver function, lipid panel every 3 months.<br /><br />

- Patient Education:<br />
  - Topics: Benefits and side effects of Ozempic, self-administration techniques, managing side effects.<br /><br />

- Lifestyle Management:<br />
  - Support: Referral to dietitian, recommendation for light physical activity.<br />
`,
  "case2.pdf": `
- Patient Overview:<br />
  Name: Jason Roberts<br />
  Age: 58 years<br />
  Condition: Type 2 Diabetes Mellitus (T2DM), diagnosed 15 years ago<br />
  Complications: Persistent hyperglycemia, obesity (BMI 34.5 kg/m²), Non-Alcoholic Fatty Liver Disease (NAFLD)<br /><br />

- Previous Medications:<br />
  - Metformin: Discontinued due to gastrointestinal issues<br />
  - SGLT2 Inhibitor (empagliflozin): Stopped after causing urinary tract infections<br />
  - GLP-1 Receptor Agonist (exenatide): Discontinued due to severe nausea<br /><br />

- Current Medical Issues:<br />
  - Diabetes Control: Recent HbA1c at 8.5%<br />
  - Obesity: Contributing to insulin resistance<br />
  - NAFLD: Related to obesity and diabetes<br /><br />

- Mounjaro Therapy:<br />
  - Prescription Reason: Inadequate control with previous medications<br />
  - Dosage: 60 mg every 28 days<br />
  - Goals: Lower HbA1c, improve glycemic control, reduce insulin resistance<br />
  - Monitoring: Every 12 weeks to evaluate effects and side effects<br /><br />

- Additional Management Plans:<br />
  - Education: Information on Mounjaro benefits, injection technique, and potential side effects<br />
  - Support: Referral to dietitian, encouragement of physical activity<br />
  - Routine Lab Monitoring: HbA1c, fasting glucose, liver function, lipid panel every 3 months<br />
`
}


export const guidelineCompliance: any = {
  "Enrolee Info.pdf":[{
    compliance_status: true,
    guideline_description:
      "Cariprazine may be covered when the client is 18 years of age or older.",
    observation:
      "The patient, is 22 years old, which is older than the required age of 18 as per the guideline.",
  },
  {
    compliance_status: true,
    guideline_description:
      "Clients 17 years of age or younger require a second opinion review with the agency-designated mental health specialist from the Second Opinion Network (SON).",
    observation: "As the patient is 22 years old, this guideline is not applicable.",
  },
  {
    compliance_status: false,
    guideline_description:
      "Client meets ONE of the following: a. History of either failure after 4 weeks, contraindication, or intolerance to THREE of the following oral atypical antipsychotics: i. Lurasidone ii. Olanzapine+Fluoxetine (Combination product or individual products taken concurrently) iii. Quetiapine b. Documentation that client has been taking cariprazine and is stabilized at the requested dose.",
    observation:
      "The patient's medical history does not provide any information about a history of failure, contraindication, or intolerance to Lurasidone, Olanzapine+Fluoxetine, or Quetiapine. Additionally, there is no documentation indicating that the patient has been taking cariprazine and is stabilized at the requested dose.",
  },
  {
    compliance_status: false,
    guideline_description: "Client has a CrCl >30mL/min.",
    observation:
      "The patient's medical history does not provide any information about the patient's CrCl levels.",
  },
  {
    compliance_status: false,
    guideline_description: "Client has no severe hepatic impairment (Child Pugh Score ≥10).",
    observation:
      "The patient's medical history does not provide any information about the patient's hepatic function or Child Pugh Score.",
  }],
  "case1.pdf":[
    {
      "compliance_status": true,
      "guideline_description": "Diagnosis of Type 2 diabetes.",
      "observation": "Ethan Parker has been diagnosed with Type 2 Diabetes Mellitus (T2DM) for over 10 years, characterized by persistent hyperglycemia. This information directly aligns with the guideline requirement for a diagnosis of Type 2 diabetes."
    },
    {
      "compliance_status": false,
      "guideline_description": "The patient meets the appropriate age limit for the requested product: a) For lixisenatide, semaglutide, tirzepatide: 18 years of age or older.",
      "observation": "The patient's age is not explicitly mentioned in the medical history provided. However, the history indicates long-term management of diabetes over 10 years and multiple medication trials, suggesting that the patient is likely over 18 years of age. Nonetheless, without explicit age information, this criterion cannot be confirmed as compliant based solely on the provided history."
    },
    {
      "compliance_status": true,
      "guideline_description": "Documentation of HbA1c ≥ 6.5 measured within the past 12 months.",
      "observation": "The patient's recent HbA1c level was documented at 8.3%, which is above 6.5%. This measurement aligns with the guideline requirement of having an HbA1c level ≥ 6.5 measured within the past 12 months."
    },
    {
      "compliance_status": true,
      "guideline_description": "History of failure, defined as inability to achieve glycemic control; intolerance; contraindication or clinically inappropriate to ALL (a-c) of the following used separately or simultaneously for a minimum of 90 days: a. Metformin at maximum or highest tolerated dose b. One preferred SGLT2 inhibitor c. One preferred GLP-1 receptor agonist.",
      "observation": "a. Metformin was discontinued due to gastrointestinal side effects and suboptimal control of blood sugar, indicating intolerance and failure to achieve glycemic control. b. Canagliflozin, an SGLT2 inhibitor, was initially effective but discontinued due to recurrent urinary tract infections and decreased efficacy over time, indicating intolerance and failure. c. Semaglutide, a GLP-1 receptor agonist, was discontinued due to nausea and minimal impact on reducing HbA1c, indicating intolerance and failure. The patient's history shows a trial and discontinuation of each medication category as required by the guideline, suggesting compliance with this criterion."
    }
  ],
  "case4.pdf":[
    {
      "compliance_status": true,
      "guideline_description": "Diagnosis of Type 2 diabetes.",
      "observation": "The patient, Henry Morgan, has been diagnosed with Type 2 Diabetes Mellitus (T2DM) for 15 years. This information is explicitly stated in the patient's medical history."
    },
    {
      "compliance_status": true,
      "guideline_description": "Patient has established atherosclerotic cardiovascular disease (ASCVD) or risk factors.",
      "observation": "Henry Morgan's medical history confirms the presence of established ASCVD, including coronary artery disease, myocardial infarction, and stable angina. Additionally, he has multiple ASCVD risk factors such as hypertension, dyslipidemia, obesity, family history of premature ASCVD, and chronic kidney disease. All these conditions are explicitly mentioned in his medical history."
    },
    {
      "compliance_status": false,
      "guideline_description": "Documentation of HbA1c ≥ 6.5 measured within the past 12 months.",
      "observation": "The patient's medical history notes that his recent HbA1c levels have remained above target, indicating suboptimal control of his diabetes. However, it does not specify the exact value or confirm that the most recent measurement is ≥ 6.5 within the past 12 months."
    },
    {
      "compliance_status": false,
      "guideline_description": "History of failure, defined as inability to achieve glycemic control; intolerance; contraindication or clinically inappropriate to ALL (a and b) of the following used separately or simultaneously for a minimum of 90 days: a. One preferred SGLT2 inhibitor; AND b. Liraglutide.",
      "observation": "The patient's history shows he was previously prescribed Dapagliflozin (an SGLT2 inhibitor) and Liraglutide. Both medications were discontinued due to side effects and limited efficacy, which meets the criteria for intolerance and inability to achieve glycemic control. The history does not specify the duration of treatment with these medications, but it implies a trial of these treatments before discontinuation."
    }
  ],
  "case3.pdf":[
    {
      "compliance_status": true,
      "guideline_description": "Diagnosis of Type 2 diabetes.",
      "observation": "Lucas Thompson's medical history explicitly states that he was diagnosed with Type 2 Diabetes Mellitus (T2DM) 12 years ago. This information directly aligns with the guideline requirement for a diagnosis of Type 2 diabetes."
    },
    {
      "compliance_status": true,
      "guideline_description": "The patient meets the appropriate age limit for the requested product: a) For lixisenatide, semaglutide, tirzepatide: 18 years of age or older.",
      "observation": "Lucas Thompson is reported to be 52 years old, which is well above the minimum age requirement of 18 years for the use of semaglutide."
    },
    {
      "compliance_status": true,
      "guideline_description": "Documentation of HbA1c ≥ 6.5 measured within the past 12 months.",
      "observation": "The patient's medical history indicates a recent HbA1c reading of 8.1%, which is above the 6.5% threshold and was measured within the last 12 months."
    },
    {
      "compliance_status": true,
      "guideline_description": "History of failure, defined as inability to achieve glycemic control; intolerance; contraindication or clinically inappropriate to ALL (a-c) of the following used separately or simultaneously for a minimum of 90 days: a. Metformin at maximum or highest tolerated dose b. One preferred SGLT2 inhibitor c. One preferred GLP-1 receptor agonist.",
      "observation": "Lucas Thompson's medical history details the use of metformin, a preferred SGLT2 inhibitor (dapagliflozin), and a GLP-1 receptor agonist (exenatide), all of which were discontinued due to side effects or limited efficacy. This indicates a history of failure with these treatments, meeting the guideline's requirement for failure with all specified drug classes."
    }
  ],
  "case2.pdf":[
    {
      "compliance_status": true,
      "guideline_description": "Diagnosis of Type 2 diabetes.",
      "observation": "The patient, Jason Roberts, has a documented history of Type 2 Diabetes Mellitus diagnosed 15 years ago. This information directly aligns with the guideline requirement for a diagnosis of Type 2 diabetes."
    },
    {
      "compliance_status": true,
      "guideline_description": "The patient meets the appropriate age limit for the requested product: a) For lixisenatide, semaglutide, tirzepatide: 18 years of age or older. b. SGLT2 inhibitor (empagliflozin) was initially effective but later led to urinary tract infections and was ineffective in maintaining glycemic control, indicating failure. c. GLP-1 receptor agonist (exenatide) caused intolerable nausea and gastrointestinal discomfort, leading to its discontinuation, indicating intolerance.",
      "observation": "The patient's history shows that all three classes of medications were used and led to either intolerance or failure to achieve glycemic control."
    }
  ]
};

export const authorization: any = {
  "Enrolee Info.pdf":{adjudication_summary: [
    `Guideline Description: Cariprazine may be covered when the client is 18 years of age or older.
Observation: The patient, is 22 years old, which is older than the required age of 18 as per the guideline.
Compliance Status: COMPLIANT`,

    `Guideline Description: Clients 17 years of age or younger require a second opinion review with the agency-designated mental health specialist from the Second Opinion Network (SON).
Observation: As the patient is 22 years old, this guideline is not applicable.
Compliance Status: COMPLIANT`,

    `Guideline Description: Client meets ONE of the following: a. History of either failure after 4 weeks, contraindication, or intolerance to THREE of the following oral atypical antipsychotics: i. Lurasidone ii. Olanzapine+Fluoxetine (Combination product or individual products taken concurrently) iii. Quetiapine b. Documentation that client has been taking cariprazine and is stabilized at the requested dose.
Observation: The patient's medical history does not provide any information about a history of failure, contraindication, or intolerance to Lurasidone, Olanzapine+Fluoxetine, or Quetiapine. Additionally, there is no documentation indicating that the patient has been taking cariprazine and is stabilized at the requested dose.
Compliance Status: NON COMPLIANT`,

    `Guideline Description: Client has a CrCl >30mL/min.
Observation: The patient's medical history does not provide any information about the patient's CrCl levels.
Compliance Status: NON COMPLIANT`,

    `Guideline Description: Client has no severe hepatic impairment (Child Pugh Score ≥10).
Observation: The patient's medical history does not provide any information about the patient's hepatic function or Child Pugh Score.
Compliance Status: NON COMPLIANT`,
  ],
  overview: `The patient's compliance status for procedure authorization is non-compliant based on explicit evidence. The patient is compliant with the age-related guidelines but is non-compliant with the guidelines related to the history of antipsychotic use, CrCl levels, and hepatic function.
  PATIENT CONDITIONS ARE COMPLIANT FOR PROCEDURE: NO
  EXPLANATION FOR NON COMPLIANCE: The patient's medical history does not provide information about a history of failure, contraindication, or intolerance to the specified antipsychotics, nor does it provide information about the patient's CrCl levels or hepatic function. For compliance authorization, additional patient evidence or medical history is needed regarding these specific guideline requirements.
  `},

  "case1.pdf":{ adjudication_summary:[
    `Guideline Description: Diagnosis of Type 2 diabetes.
Observation: Ethan Parker has been diagnosed with Type 2 Diabetes Mellitus (T2DM) for over 10 years, characterized by persistent hyperglycemia. This information directly aligns with the guideline requirement for a diagnosis of Type 2 diabetes.
Compliance Status: COMPLIANT
`,
    `Guideline Description: The patient meets the appropriate age limit for the requested product: a) For lixisenatide, semaglutide, tirzepatide: 18 years of age or older.
Observation: The patient's age is not explicitly mentioned in the medical history provided. However, the history indicates long-term management of diabetes over 10 years and multiple medication trials, suggesting that the patient is likely over 18 years of age. Nonetheless, without explicit age information, this criterion cannot be confirmed as compliant based solely on the provided history.
Compliance Status: NON COMPLIANT
`,
    `Guideline Description: Documentation of HbA1c ≥ 6.5 measured within the past 12 months.
Observation: The patient's recent HbA1c level was documented at 8.3%, which is above 6.5%. This measurement aligns with the guideline requirement of having an HbA1c level ≥ 6.5 measured within the past 12 months.
Compliance Status: COMPLIANT
`,
    `Guideline Description: History of failure, defined as inability to achieve glycemic control; intolerance; contraindication or clinically inappropriate to ALL (a-c) of the following used separately or simultaneously for a minimum of 90 days:
a. Metformin at maximum or highest tolerated dose
b. One preferred SGLT2 inhibitor
c. One preferred GLP-1 receptor agonist.
Observation:
a. Metformin was discontinued due to gastrointestinal side effects and suboptimal control of blood sugar, indicating intolerance and failure to achieve glycemic control.
b. Canagliflozin, an SGLT2 inhibitor, was initially effective but discontinued due to recurrent urinary tract infections and decreased efficacy over time, indicating intolerance and failure.
c. Semaglutide, a GLP-1 receptor agonist, was discontinued due to nausea and minimal impact on reducing HbA1c, indicating intolerance and failure.
The patient's history shows a trial and discontinuation of each medication category as required by the guideline, suggesting compliance with this criterion.
Compliance Status: COMPLIANT
`

  ],
  overview:`The patient's medical history is compliant with the guidelines for diagnosis of Type 2 diabetes, documentation of HbA1c ≥ 6.5, and history of failure with specified treatments. However, the patient's compliance with the age criterion cannot be confirmed due to lack of explicit age information in the provided history.
PATIENT CONDITIONS ARE COMPLIANT FOR PROCEDURE: NO
EXPLANATION FOR NON COMPLIANCE: The patient's age is not explicitly documented in the medical history provided. To achieve compliance, explicit confirmation of the patient being 18 years of age or older is required as per the guideline.
`

  },
  "case4.pdf":{
    adjudication_summary:[
      ` Guideline Description: Diagnosis of Type 2 diabetes.
Observation: The patient, Henry Morgan, has been diagnosed with Type 2 Diabetes Mellitus (T2DM) for 15 years. This information is explicitly stated in the patient's medical history.
Compliance Status: COMPLIANT
`,
`Guideline Description: Patient has established atherosclerotic cardiovascular disease (ASCVD) or risk factors.
Observation: Henry Morgan's medical history confirms the presence of established ASCVD, including coronary artery disease, myocardial infarction, and stable angina. Additionally, he has multiple ASCVD risk factors such as hypertension, dyslipidemia, obesity, family history of premature ASCVD, and chronic kidney disease. All these conditions are explicitly mentioned in his medical history.
Compliance Status: COMPLIANT
`,
`Guideline Description: Documentation of HbA1c ≥ 6.5 measured within the past 12 months.
Observation: The patient's medical history notes that his recent HbA1c levels have remained above target, indicating suboptimal control of his diabetes. However, it does not specify the exact value or confirm that the most recent measurement is ≥ 6.5 within the past 12 months.     
Compliance Status: NON COMPLIANT
`,
`Guideline Description: History of failure, defined as inability to achieve glycemic control; intolerance; contraindication or clinically inappropriate to ALL (a and b) of the following used separately or simultaneously for a minimum of 90 days: a. One preferred SGLT2 inhibitor; AND b. Liraglutide.
Observation: The patient's history shows he was previously prescribed Dapagliflozin (an SGLT2 inhibitor) and Liraglutide. Both medications were discontinued due to side effects and limited efficacy, which meets the criteria for intolerance and inability to achieve glycemic control. The history does not specify the duration of treatment with these medications, but it implies a trial of these treatments before discontinuation.
Compliance Status: NON COMPLIANT
`
    ],
    overview:`The patient is compliant with the guidelines regarding the diagnosis of Type 2 diabetes and the presence of ASCVD or risk factors. However, he is non-compliant with the guidelines concerning the documentation of HbA1c levels and the history of medication failure due to unspecified treatment durations.
PATIENT CONDITIONS ARE COMPLIANT FOR PROCEDURE: NO
EXPLANATION FOR NON COMPLIANCE: The patient's medical history lacks specific documentation of HbA1c levels being ≥ 6.5 within the last 12 months and does not provide explicit durations for the treatment with Dapagliflozin and Liraglutide. To achieve compliance, the medical history needs to include a recent HbA1c measurement and confirm that each medication was used for at least 90 days.
` },
  "case3.pdf":{
    adjudication_summary:[
      `Guideline Description: Diagnosis of Type 2 diabetes.
Observation: Lucas Thompson's medical history explicitly states that he was diagnosed with Type 2 Diabetes Mellitus (T2DM) 12 years ago. This information directly aligns with the guideline requirement for a diagnosis of Type 2 diabetes.
Compliance Status: COMPLIANT
`,
`Guideline Description: The patient meets the appropriate age limit for the requested product: a) For lixisenatide, semaglutide, tirzepatide: 18 years of age or older.
Observation: Lucas Thompson is reported to be 52 years old, which is well above the minimum age requirement of 18 years for the use of semaglutide.        
Compliance Status: COMPLIANT
`,
`Guideline Description: Documentation of HbA1c ≥ 6.5 measured within the past 12 months.
Observation: The patient's medical history indicates a recent HbA1c reading of 8.1%, which is above the 6.5% threshold and was measured within the last 12 months.
Compliance Status: COMPLIANT
`,
`Guideline Description: History of failure, defined as inability to achieve glycemic control; intolerance; contraindication or clinically inappropriate to ALL (a-c) of the following used separately or simultaneously for a minimum of 90 days:
a. Metformin at maximum or highest tolerated dose
b. One preferred SGLT2 inhibitor
c. One preferred GLP-1 receptor agonist.
Observation: Lucas Thompson's medical history details the use of metformin, a preferred SGLT2 inhibitor (dapagliflozin), and a GLP-1 receptor agonist (exenatide), all of which were discontinued due to side effects or limited efficacy. This indicates a history of failure with these treatments, meeting the guideline's requirement for failure with all specified drug classes.
Compliance Status: COMPLIANT
`
    ],
    overview:`Lucas Thompson's medical history complies with all the specified clinical guidelines for the use of Ozempic (semaglutide). His diagnosis, age, recent HbA1c levels, and history of treatment failures align with the requirements set forth in the guidelines.
PATIENT CONDITIONS ARE COMPLIANT FOR PROCEDURE: YES`

  },
  "case2.pdf":{
    adjudication_summary:[
      `Guideline Description: Diagnosis of Type 2 diabetes.
Observation: The patient, Jason Roberts, has a documented history of Type 2 Diabetes Mellitus diagnosed 15 years ago. This information directly aligns with the guideline requirement for a diagnosis of Type 2 diabetes.
Compliance Status: COMPLIANT
`,
`Guideline Description: The patient meets the appropriate age limit for the requested product: a) For lixisenatide, semaglutide, tirzepatide: 18 years of age or older.
b. SGLT2 inhibitor (empagliflozin) was initially effective but later led to urinary tract infections and was ineffective in maintaining glycemic control, indicating failure.
c. GLP-1 receptor agonist (exenatide) caused intolerable nausea and gastrointestinal discomfort, leading to its discontinuation, indicating intolerance.   
The patient's history shows that all three classes of medications were used and led to either intolerance or failure to achieve glycemic control.
Compliance Status: COMPLIANT
`
    ],
    overview:`The patient, Jason Roberts, is compliant with all the required guidelines for the use of Mounjaro (tirzepatide) based on his medical history and the clinical guidelines provided.
PATIENT CONDITIONS ARE COMPLIANT FOR PROCEDURE: YES
`
  }
};
