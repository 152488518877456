import { useDispatch, useSelector } from "react-redux";
import store, { logoutUser, RootState, updateAccessToken } from "../store";
import { ActiveDirectoryService } from "../api";
import { HttpStatusCode } from "axios";
import { useCallback } from "react";
import { jwtDecode } from "jwt-decode";


export const useAuthUser = () => {
  const { user } = useSelector((state: RootState) => state.authUser);
  const { accessToken, firstName, lastName, email } = user;
  const dispatch = useDispatch();

  const handleRefreshToken = async () => {
    try {
      const response = await ActiveDirectoryService.activeDirectoryRefreshToken(user.refreshToken);
      if (response.data && response.status === HttpStatusCode.Created) {
        dispatch(updateAccessToken(response.data.data.accessToken));
      } else {
        const { data } = await ActiveDirectoryService.activeDirectoryLogout(window.location.origin);
        if (data) {
          dispatch(logoutUser());
          window.location.assign(data.data.logoutUri);
        }
      }
    } catch (error) {
      console.error("Error refreshing access token:", error);
    }
  };

  const isTokenExpired = (decodedToken: { exp?: number }) => {
    if (decodedToken?.exp && decodedToken.exp < +(Date.now() / 1000).toFixed()) {
      return true;
    }
    return false;
  }

  const verifyUserAuth = async () => {
    if (!accessToken) {
      dispatch(logoutUser());
      return;
    }

    const decodedToken = jwtDecode<{ exp?: number }>(accessToken);
    if (!decodedToken.exp || isTokenExpired(decodedToken)) {
        return await handleRefreshToken();
    }
    return true;
   
  };

  

  return {
    firstName,
    lastName,
    accessToken,
    roleCode: user.role ? user.role.code : "",
    roleName: user.role ? user.role.name : "",
    email,
    verifyUserAuth
  };
};


