import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Loading } from "../../components";
import { PageAsideLeftWrapper } from "./PageAsideLeftWrapper";
import { PageContainerWrapper } from "./PageContainerWrapper";
import { useAuthUser } from "../../hooks";

interface Props {
  loading?: boolean;
  containerClassName?: string;
  pageLeftAsideChildren?: JSX.Element;
  pageContainerChildren: JSX.Element;
}

export const PageWrapper = ({
  loading,
  pageContainerChildren,
  pageLeftAsideChildren,
  containerClassName,
}: Props) => {
  const noGrid = !pageLeftAsideChildren || loading;
  const { verifyUserAuth } = useAuthUser();
  const [verifyingAuth, setVerifyingAuth] = useState(true);

  useEffect(() => {
    verifyUserAuth().then(() => setVerifyingAuth(false));
  }, []);

  return (
    <Content className={`${noGrid ? "no-grid" : ""}`}>
      {loading || verifyingAuth ? (
        <Loading />
      ) : (
        <>
          {pageLeftAsideChildren && (
            <PageAsideLeftWrapper>{pageLeftAsideChildren}</PageAsideLeftWrapper>
          )}
          <PageContainerWrapper className={containerClassName}>
            {pageContainerChildren}
          </PageContainerWrapper>
        </>
      )}
    </Content>
  );
};
