import React, { useState,useEffect } from "react";
import { useLocation, useParams,useOutletContext } from "react-router-dom";
import { CopilotContainer, PageWrapper } from "../containers";
import { IOutletContext } from "../navigation/PrivateLayout";
import { PAGE_URL} from "../utils/constants";

export const Project = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const selectedProjectName = location?.state?.selectedProjectName || "Projects";
  const { projectId } = useParams();
  const { setPageHeader, resetPageHeader } = useOutletContext<IOutletContext>();
  
  useEffect(() => {
    setPageHeader({
      pageRoute: `${PAGE_URL.PROJECTS}/{projectId}`,
      pageTitle: selectedProjectName,
      displayBackButton: true,
    });
    return () => resetPageHeader();
  }, [projectId]);


  return <PageWrapper loading={isLoading} pageContainerChildren={<CopilotContainer />} />;
};

export default Project;
