import React, { useEffect, useState } from "react";
import { PdfViewer } from "../../components";
import { Button, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { patientSummaryService, uploadFileFolderService } from "../../api";
import { openNotificationWithIcon } from "../../utils";
import "./case-details-container.scss";
import {
  DiagnosisProps,
  GuidelineCompliance,
  PatientSummary,
  PriorAuthorization,
  ProcedureProps,
} from "../../containers";
import PatientSummaryNew from "../patient-summary/PatientSummaryNew";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setQuestionnaire, setSignedUrl, setSummaryType } from "../../store";
import ResponseLetter from "../response-letter/ResponseLetter";
import { fileNamesWithResponseLetter } from "../response-letter/constants";
import Summary from "../patient-summary/Summary";

type Props = {
  pageData: {
    key: React.Key;
    fileFolder: string;
    createdOn: string;
    updatedOn: string;
    status: string;
    action: string;
    isFolder: boolean;
    name: string;
    documentUUID: string;
  };
  projectId: number;
  documentUUID: string;
};

const documentsWithHardCodedValues = [
  "Enrolee Info.pdf",
  "PBMCase1.pdf",
  "PBMCase2.pdf",
  "PBMCase3.pdf",
  "PBMCase4.pdf",
  "146132852320240319.pdf",
  "188685422520240319.pdf",
  "156116407220240319.pdf",
  "165882770520240319.pdf",
  "198485391920240319.pdf",
  "Tenoudji 91213 0524.pdf",
  "FX037042.pdf",
  "Billing Document - 8007307259.pdf",
  "Inv_31289_from_SILICONES_PLUS_INC._1412.pdf",
  "Inv_65375_from_Exakt_Technologies_Inc_10832.pdf",
  "Invoice-2024-83163-00.pdf",
  "case1.pdf",
  "case4.pdf",
  "case3.pdf",
  "case2.pdf",
  "1ccb3072-cfdd-4898-a082-c891e27d01f0.pdf"
];

export const CaseDetailsContainer = ({ pageData, projectId, documentUUID }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [tabDisabled, setTabDisabled] = useState({ "1": false, "2": true, "3": true, "4": true });
  const [procCode, setProcCode] = useState<ProcedureProps>({
    procedureCode: "",
    procedureDescription: "",
  });
  const [diagCode, setDiagCode] = useState<DiagnosisProps>({
    diagnosisCode: "",
    diagnosisDescription: "",
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([] as string[] | undefined);
  const { projectDetails } = useSelector((state: RootState) => state.document)
  const [ lastQuestion ,setLastQuestion]= useState<string>("")

  const dispatch = useDispatch();

  const navigateToTab = (
    tabKey: string,
    procedureCode?: any,
    diagnosisCode?: any,
    checboxes?: string[]
  ) => {
    if (procedureCode) {
      setProcCode(procedureCode);
    }
    if (diagnosisCode) {
      setDiagCode(diagnosisCode);
    }
    setActiveTab(tabKey);
    setTabDisabled((prev) => ({ ...prev, [tabKey]: false }));
    setSelectedCheckboxes(checboxes);
  };

  const getSignedUrlByPath = async () => {
    try {
      const { data } = await uploadFileFolderService.getSignedUrlByFilepath(
        pageData?.name,
        projectId
      );
      setFileUrl(data.data.signedUrl);
      dispatch(setSignedUrl(data.data.signedUrl)); 
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    getSignedUrlByPath();
  }, []);

  useEffect(() => {
    const parentElem = document.querySelector(".tabView");
    if (parentElem) {
      parentElem.scrollIntoView({ behavior: "smooth", block: "start" });
      window.scrollTo(0, 0);
      parentElem.scrollTo(0, 0);
    }
  }, [activeTab]);

  const handleTabsChange = (key: string) => {
    setActiveTab(key);
    switch (key) {
      case "1":
        dispatch(setSummaryType("Patient Summary"));
        break;
      case "2":
        dispatch(setSummaryType("Guidelines Summary"));
        break;
      case "3":
        dispatch(setSummaryType("Recommendation Summary"));
        break;
    }
  };

  return (
    <div className="case-details-wrapper">
      <div style={{ flex: 1, flexBasis: "10vw", width: "40%" }}>
        <PdfViewer
          currPage={currentPage}
          pdf={fileUrl}
          displayPagination
          setCurrPage={setCurrentPage}
          sourcePopup={false}
          fileName={pageData?.name || ""}
        />
      </div>
      <Tabs
        centered
        activeKey={activeTab}
        defaultActiveKey="1"
        onChange={handleTabsChange}
        tabBarGutter={48}
        className="tabView"
      >
        <TabPane
          tab={
            <div className="tabView-title">
              <span>1</span> Patient Overview
            </div>
          }
          key="1"
        >
          {projectDetails.projectName==="Pharmacy Auths" ? (
            <PatientSummaryNew
              documentUUID={documentUUID}
              projectId={projectId}
              enableNextTab={(procedureCode, diagnosisCode) =>
                navigateToTab("2", procedureCode, diagnosisCode)
              }
              documentName={pageData?.name || ""}
              setLastQuestion={setLastQuestion}
            />
          ) : (
            <PatientSummary
              documentUUID={documentUUID}
              projectId={projectId}
              enableNextTab={(procedureCode, diagnosisCode) =>
                navigateToTab("2", procedureCode, diagnosisCode)
              }
              pdf={fileUrl}
              fileName={pageData?.name || ""}
            />
          )}
        </TabPane>
        {projectDetails.projectName==="Pharmacy Auths" &&
        <TabPane
          tab={
            <div className="tabView-title">
              <span>2</span>Case Summary
            </div>
          }
          key="2"
          disabled={tabDisabled["2"]}
        >
            <Summary
              documentUUID={documentUUID}
              projectId={projectId}
              enableNextTab={(procedureCode, diagnosisCode) =>
                navigateToTab("3", procedureCode, diagnosisCode)
              }
              documentName={pageData?.name || ""}
              setFileUrl={setFileUrl}
              fileUrl={fileUrl}
              procedureCode={procCode}
              diagnosisCode={diagCode}
              lastQuestion={lastQuestion}
            />
        </TabPane>}
       {projectDetails.projectName!=="Pharmacy Auths" && <><TabPane
          tab={
            <div
              className="tabView-title"
              onClick={() => dispatch(setSummaryType("Guidelines Summary"))}
            >
              <span>{projectDetails.projectName==="Pharmacy Auths" ? 3 : 2}</span> Guideline Compliance
            </div>
          }
          key={projectDetails.projectName==="Pharmacy Auths" ? "3" : "2"}
          disabled={projectDetails.projectName==="Pharmacy Auths" ? tabDisabled["3"] : tabDisabled["2"]}
        >
          <GuidelineCompliance
            documentUUID={documentUUID}
            projectId={projectId}
            enableNextTab={(checkboxes: string[]) =>
              navigateToTab("3", procCode, diagCode, checkboxes)
            }
            procedureCode={procCode}
            diagnosisCode={diagCode}
            fileName={pageData?.name || ""}
          />
        </TabPane>
        <TabPane
          tab={
            <div className="tabView-title">
              <span>{projectDetails.projectName==="Pharmacy Auths" ? 4 : 3}</span> Recommendation
            </div>
          }
          key={projectDetails.projectName==="Pharmacy Auths" ? "4" : "3"}
          disabled={projectDetails.projectName==="Pharmacy Auths" ? tabDisabled["4"] : tabDisabled["3"]}
        >
          <PriorAuthorization
            pageData={pageData}
            projectId={projectId}
            procedureCode={procCode}
            diagnosisCode={diagCode}
            selectedCheckboxes={selectedCheckboxes}
            fileName={pageData?.name || ""}
            enableNextTab={(checkboxes?: string[]) =>
              navigateToTab("4", procCode, diagCode, checkboxes)
            }
          />
        </TabPane></>}
        {projectId === 53 && fileNamesWithResponseLetter.includes(pageData?.name) && (
          <TabPane
            tab={
              <div className="tabView-title">
                <span>4</span> Response Letter
              </div>
            }
            key="4"
            disabled={tabDisabled["4"]}
          >
            <ResponseLetter fileName={pageData?.name} documentUUID={pageData?.documentUUID} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};
