export const responseLetterValues = {
  "NewSampleAuth2.pdf": {
    shortCollege: `Notice of Action about your Treatment Request

 

This request for healthcare services has been reviewed to determine if it is a covered benefit and/or if the service is medically necessary. This request has been denied. If you have not yet received these services and you proceed with them, they will not be covered and you will be responsible for the costs.

 

The provider that requested these services will receive a copy of this letter. You or your provider can request a copy of the guidelines that were used to make this decision. Your provider can also contact a doctor from our end to discuss this decision further.

 

This notice also outlines your right to appeal our decision. You have the opportunity to submit written comments, documents, or other information related to the appeal.

 

**Date:** September 15, 2023

**Member ID:** 1123000560

**Member Name:** Alexi Lala

**Provider Name:** McBroklyn ABPN GRR

**Date of Denial:** September 14, 2023

 

**Your request was denied**

We have denied the medical service/item listed below requested by you or your doctor:

Magnetic resonance imaging (MRI) of the brain without contrast material, followed by contrast material and further sections.

 

**Why did we deny your request?**

We have denied the medical service/item listed above because the patient's medical history does not comply with the clinical guideline for headaches. The guideline focuses on headaches related to trauma, spontaneous intracranial hypotension, infection, aneurysm, dissection, venous sinus thrombosis, or other specific diagnoses. However, the patient's headaches are associated with chronic migraines and idiopathic normal pressure hydrocephalus (NPH), conditions not specified in the guideline. Additionally, a previously undiagnosed meningioma was identified in the patient, which is also not mentioned in the guideline. Therefore, the patient's condition does not align with the specific conditions outlined in the clinical guideline.

 

Sincerely, 
`,
    shortMiddleSchool: `Notice of Action about your Treatment Request

 

**Date:** September 15, 2023

**Member ID:** 1123000560

**Member Name:** Alexi Lala

**Provider Name:** McBroklyn ABPN GRR

**Date of Denial:** September 14, 2023

 


**Your request was denied**

We have denied the Magnetic resonance imaging (MRI) of your knee joint without contrast material requested by you or your doctor.

 

**Why did we deny your request?**

We have denied the MRI because your medical history does not meet the guidelines for this type of advanced imaging. The guidelines say that MRIs are needed for very specific conditions like serious infections, bone breaks, or tumors. Your current knee problem does not match these conditions. Also, the guidelines suggest a different type of MRI for the issues you are experiencing, which was not requested.

This decision can be reviewed if you think it was a mistake. You or your doctor can ask us for the rules we used to decide. Your doctor can also talk to a doctor from our team about this.

If you have any questions or if you want to appeal this decision, please let us know. You can send us more information or anything else you think might help your case.

 

Sincerely,
`,
    elaborateCollege: `Notice of Action Regarding Your Treatment Request

 

**Date:** September 15, 2023

**Member ID:** 1123000560

**Member Name:** Alexi Lala

**Provider Name:** McBroklyn ABPN GRR

**Date of Denial:** September 14, 2023

 

Dear Ms. Lala,

**Subject:** Denial of Request for Magnetic Resonance Imaging (MRI) Procedure


 

We regret to inform you that your request for the medical procedure, specifically a Magnetic Resonance Imaging (MRI) of the brain without contrast material, followed by contrast material and further sections, has been reviewed and subsequently denied. This decision was made after careful consideration of your current medical condition in relation to the established clinical guidelines.


 

**Reason for Denial:**  
The primary reason for the denial of your request lies in the non-compliance of your medical condition with the specific clinical guidelines that govern the approval of such procedures. According to our records and the clinical guideline focused on headaches, the indicated use of an MRI of the brain is typically reserved for conditions related to trauma, spontaneous intracranial hypotension, infection, aneurysm, dissection, venous sinus thrombosis, or other specific diagnoses.

Your medical history indicates that your headaches are primarily associated with chronic migraines and idiopathic normal pressure hydrocephalus (NPH). Additionally, a previously undiagnosed meningioma has been identified. These conditions, as per the guideline, do not meet the criteria for the recommended use of this MRI procedure. The guideline explicitly requires a direct association with the aforementioned conditions to justify the medical necessity and appropriateness of the procedure.


 

**Implications of this Decision:**  
Please be advised that if you choose to proceed with the MRI procedure despite this denial, the costs incurred will not be covered under your current health plan and will be your responsibility.


 

**Your Right to Appeal:**  
You have the right to appeal this decision. Should you decide to pursue an appeal, you are encouraged to submit written comments, documents, or other relevant information that might impact the review of your case. Your healthcare provider, Dr. Jane Smith, can also engage in a peer-to-peer discussion with our Healthcare physician to further discuss and potentially contest this decision.


 

**Request for Additional Information:**  
Both you and your provider have the right to request a copy of the specific rules and guidelines that were applied in making this decision. Such information can be instrumental in understanding the basis of this denial and in preparing for a potential appeal.


 

We understand that this may be disappointing news, and we are committed to providing you with the support you need during this process. Please do not hesitate to reach out to our member services if you require further clarification or assistance regarding this matter.


 

Sincerely,  
`,
    elaborateMiddleSchool: `Notice of Action about your Treatment Request

 

**Date:** September 15, 2023

**Member ID:** 1123000560

**Member Name:** Alexi Lala

**Provider Name:** McBroklyn ABPN GRR

**Date of Denial:** September 14, 2023

 

Dear Alexi Lala,

We are writing to let you know about the decision on your recent request for a medical procedure. Unfortunately, we cannot approve the Magnetic Resonance Imaging (MRI) of your brain that your doctor asked for. This letter explains why we made this decision and what you can do next.


 

**Your request was denied**
The medical procedure you asked for is an MRI of the brain without contrast material, followed by contrast material and further sections. After reviewing your medical needs, we have decided not to approve this request.


 

**Why did we deny your request?**
We understand that you have been experiencing headaches. However, the guidelines we follow to approve treatments like MRIs are very specific. They list certain medical conditions that need to be present for an MRI to be necessary. These conditions include headaches caused by things like trauma (a serious injury), infections, or other very specific medical issues.     


 

Your medical records show that your headaches are due to chronic migraines and a condition called idiopathic normal pressure hydrocephalus (NPH). You also have a previously undiagnosed condition called meningioma. None of these conditions are mentioned in our guidelines for approving an MRI for headaches.


 

Because your situation does not match the conditions listed in our guidelines, we cannot approve the MRI. If you choose to have the MRI without our approval, please be aware that it will not be covered by your insurance, and you will need to pay for it yourself.


 

**What can you do next?**
You and your doctor have the right to disagree with our decision. You can ask us to reconsider by filing an appeal. This means you send us more information or reasons why you think the MRI should be approved. You can also ask us for a copy of the rules we used to make this decision. Your doctor can talk to one of our doctors if they want to discuss this further.      


 

We hope this letter helps you understand our decision. If you have any questions or need help with the appeal process, please feel free to contact us.
`,
  },
  "PriorAuthSample10_1 (2).pdf": {
    shortCollege: `Notice of Action about your Treatment Request

 

**Date:** October 1, 2023

**Member ID:** 12312341

**Member Name:** Micheal Davids

**Provider Name:** FRANCE

**Date of Denial:** October 1, 2023


 

**Your request was denied**

We have denied the medical service listed below requested by you or your doctor:

Magnetic resonance imaging (MRI) of knee joint without contrast material    


 

**Why did we deny your request?**

We have denied the above medical service because it does not comply with the established clinical guidelines necessary for approval. According to the guidelines, advanced imaging such as MRI is medically necessary for conditions including congenital and developmental issues, infections, trauma involving fractures, and tumors/neoplasms, or following an unsuccessful minimum of 6 weeks of conservative treatment. Your medical records indicate a sports injury with symptoms suggesting a potential meniscal tear, which does not meet the criteria outlined for advanced imaging. Additionally, the guidelines suggest MR arthrography for osteochondral lesions, which is not applicable in your case based on your medical history. Consequently, the recommendation for an MRI does not align with the necessary clinical guidelines for this type of advanced imaging.   


 

This notice also explains your right to appeal our decision. You have the opportunity to submit written comments, documents, or other information relating to the appeal. The provider that requested these services will receive a copy of this letter. You or your provider can request a copy of the rules that were used to make this decision. Your provider can also contact with one of our doctors to discuss this decision further.

 

Sincerely,
`,
shortMiddleSchool: `Notice of Action about your Treatment Request

 

**Date:** October 1, 2023

**Member ID:** 12312341

**Member Name:** Micheal Davids

**Provider Name:** FRANCE

**Date of Denial:** October 1, 2023

 

**Your request was denied**

We have denied the Magnetic resonance imaging (MRI) of your knee joint without contrast material requested by you or your doctor.

 

**Why did we deny your request?**

We have denied the MRI because your medical history does not meet the guidelines for this type of advanced imaging. The guidelines say that MRIs are needed for very specific conditions like serious infections, bone breaks, or tumors. Your current knee problem does not match these conditions. Also, the guidelines suggest a different type of MRI for the issues you are experiencing, which was not requested.

 

This decision can be reviewed if you think it was a mistake. You or your doctor can ask us for the rules we used to decide. Your doctor can also talk to a doctor from our team about this.

 

If you have any questions or if you want to appeal this decision, please let us know. You can send us more information or anything else you think might help your case.

 

Sincerely,
`,
    elaborateCollege: `Notice of Action about your Treatment Request

 


**Date:** October 1, 2023

**Member ID:** 12312341

**Member Name:** Micheal Davids

**Provider Name:** FRANCE

**Date of Denial:** October 1, 2023


 

Subject: Denial of Request for Magnetic Resonance Imaging (MRI) of Knee Joint 


 

Dear Mr. Davids,

We are writing to inform you that your request for a Magnetic Resonance Imaging (MRI) of the knee joint without contrast material, as submitted by Dr. Jane Smith, has been reviewed and subsequently denied. This decision was made after careful consideration of your current medical condition and the prevailing clinical guidelines that govern the provision of such advanced imaging services.

 


**Your request was denied for the following medical service:**
Magnetic resonance imaging (MRI) of the knee joint without contrast material.

 


**Reason for Denial:**
Upon review, it has been determined that the requested MRI procedure does not comply with the established clinical guidelines necessary for approval. Your medical records indicate a sports injury to the left knee, characterized by symptoms that suggest a potential meniscal tear. Despite these symptoms and non-diagnostic radiographs, the clinical guidelines for the authorization of an MRI require that the patient's condition must fall into one of the following categories: congenital and developmental conditions, infections, trauma cases involving fractures, tumors/neoplasms, or cases where there has been a failure of at least 6 weeks of conservative management. Your case does not meet these criteria.

 


Additionally, the guidelines specify the use of MR arthrography for diagnosing conditions such as osteochondral lesions, which are not indicated in your medical history. Given these factors, the recommendation for an MRI at this stage is not aligned with the clinical guidelines, which are designed to ensure that such advanced imaging is both medically necessary and appropriate given the specific circumstances of the patient's health condition.

 


**Next Steps:**
This decision does not affect your right to seek further medical opinion or to appeal our decision. Both you and your healthcare provider, Dr. Jane Smith, have the right to request a review of the rules applied in making this decision. Dr. Smith may also contact a doctor directly to discuss this case in detail.

 


If you choose to proceed with the MRI without the approval of this request, please be advised that the costs incurred will not be covered by your insurance plan and will be your responsibility.

 


**Appeal Process:**
You are entitled to appeal this decision. Should you decide to do so, you may submit written comments, documents, or other information that you deem relevant to your appeal. Please submit your appeal within 30 days from the receipt of this letter for it to be considered timely.

 


We understand that this may be disappointing news, and we are here to assist you through this process. If you have any questions or need further clarification regarding this decision or the appeals process, please do not hesitate to contact our member services.
`,
    elaborateMiddleSchool: `Notice of Action about your Treatment Request

 


**Date:** October 1, 2023

**Member ID:** 12312341

**Member Name:** Micheal Davids

**Provider Name:** FRANCE

**Date of Denial:** October 1, 2023

Dear Micheal Davids,

 


We are writing to inform you about the decision regarding your recent request for a Magnetic Resonance Imaging (MRI) of your knee joint without contrast material. Unfortunately, after careful review, this request has been denied.     

 


**Your request was denied for the following medical service:**
Magnetic resonance imaging (MRI) of knee joint without contrast material    

 


**Why did we deny your request?**
We have denied the requested MRI because it does not meet the necessary medical guidelines for approval. According to the guidelines, MRIs are approved for specific conditions such as congenital and developmental issues, infections, trauma involving fractures, tumors, or after at least 6 weeks of other treatments have been tried without success. Your current medical situation, involving a sports injury to the left knee suspected to be a meniscal tear, does not fit these categories. Additionally, the guidelines suggest a different type of MRI for conditions like osteochondral lesions, which is not mentioned in your medical history.

 


This decision was made based on the information provided by your healthcare provider and the established clinical guidelines for medical necessity. It is important to understand that if you choose to proceed with the MRI without approval, the costs will not be covered by your insurance, and you will be responsible for the payment.

 


**What can you do next?**
**Review the decision**: You or your healthcare provider can request a copy of the specific rules and guidelines used to make this decision.
**Discuss with your provider**: Your healthcare provider can contact a doctor to discuss this decision further if there are additional details or clarifications needed.
**File an appeal**: You have the right to appeal this decision. You can submit written comments, documents, or other information that you think might change the decision.

 


We are here to help you understand and navigate this process. If you have any questions or need further assistance, please do not hesitate to contact us.
`,
  },
  "PriorAuthSample11_1.pdf": {
    shortCollege: `Notice of Action about your Treatment Request

 

This request for healthcare services has been reviewed to determine if it is a covered benefit and/or medically necessary. Unfortunately, this request has been denied. Should you choose to proceed with these services, please be aware that they will not be covered, and you will be responsible for the associated costs.

 

The provider who submitted this request will receive a copy of this letter. Both you and your provider have the right to request the specific guidelines used in making this decision. Additionally, your provider can engage in a discussion with our Healthcare physician regarding this outcome.

 

You are entitled to appeal this decision. We encourage you to submit any relevant written comments, documents, or other information as part of your appeal process.

 

**Date:** September 30, 2023

**Member ID:** 123987456

**Member Name:** Jessica Rhyne

**Provider Name:** Dr. Jason Momoa

**Date of Denial:** September 30, 2023

 

Your request for the following medical service has been denied:
Magnetic resonance imaging (MRI) of lumbar spinal canal and contents without contrast material, followed by contrast material and further sections.

 

**Reason for Denial:**
The requested MRI procedure does not comply with established clinical guidelines given your medical history and current condition. Specifically, your previous non-compliance with clinical guidelines, the absence of certain medical prerequisites for this type of advanced imaging, and the lack of pediatric status which is required for specific procedures, have led to this decision. As such, we are unable to approve the MRI as medically necessary at this time.

 

We understand this may be disappointing news and encourage you to discuss this decision with your healthcare provider or contact us for further clarification on your appeal rights. 
`,
shortMiddleSchool: `Notice of Action about your Treatment Request

 

**Date:** October 1, 2023

**Member ID:** 12312341

**Member Name:** Micheal Davids

**Provider Name:** FRANCE

**Date of Denial:** October 1, 2023

 

**Your request was denied**

We have denied the medical service you asked for: Magnetic resonance imaging (MRI) of lumbar spinal canal and contents without contrast material, followed by contrast material and further sections.    

 

**Why did we deny your request?**

We denied this MRI because your medical records do not meet the needed rules for this test. The rules say you need certain conditions to have this MRI, but your records do not show these conditions. Also, you did not complete some treatments that should be tried first.

 

If you have questions or want to appeal this decision, you can send us more information or ask us to review it again.

 

Sincerely,
CareFirst Insurance
`,
elaborateCollege: `Notice of Action about your Treatment Request

 

**Date:** September 30, 2023

**Member ID:** 123987456

**Member Name:** Jessica Rhyne

**Provider Name:** Dr. Jason Momoa

**Date of Denial:** September 30, 2023

 

Dear Ms. Rhyne,

 

Subject: Denial of Request for Magnetic Resonance Imaging (MRI) Procedure

 

We are writing to inform you that your request for the Magnetic Resonance Imaging (MRI) of the lumbar spinal canal and contents without contrast material, followed by contrast material and further sections, has been reviewed and subsequently denied. This decision was made after careful consideration of your medical history and the applicable clinical guidelines.      

 

**Your Request was Denied:**
The medical services/items listed below requested by you or your doctor have been denied:
MRI of the lumbar spinal canal and contents without contrast material, followed by contrast material and further sections.

 

**Why Did We Deny Your Request?**
We have denied the medical services/items listed above for the following reasons:
1. **Non-Compliance with Clinical Guidelines:** Your medical history reveals multiple instances where clinical guidelines were not adhered to. Despite a history of malignancy and bilateral leg pain, a previous MRI indicated spondylosis without any enhancing mass. The clinical guidelines clearly stipulate that advanced imaging, such as an MRI with contrast, is warranted under specific conditions which do not align with your current medical status.

2. **Lack of Prerequisite Medical Findings:** The guidelines require the discovery of a syrinx on a noncontrast examination as a prerequisite for conducting a repeat MRI with contrast. Your medical records do not indicate the presence of such findings.

3. **Inadequate Response to Conservative Management:** The guidelines also necessitate that the patient's symptoms must not have responded to at least 6 weeks of conservative management before proceeding with advanced imaging techniques. Your medical documentation does not confirm adherence to this requirement.

4. **Patient Age and Specific Conditions:** Certain imaging procedures are specifically recommended for pediatric patients or under particular medical conditions, none of which apply to your case as per the information provided by your healthcare provider.

 

**Your Right to Appeal:**
You have the right to appeal this decision. You may submit written comments, documents, or other information relevant to your appeal. We encourage you to discuss this decision with your healthcare provider, who may also contact our Healthcare physician to discuss the clinical rationale behind the denial of your request.

The provider that requested these services will receive a copy of this letter. You or your provider can request a copy of the rules and guidelines that were used to make this decision. 

 

We understand that this may be disappointing news, and we encourage you to reach out to your healthcare provider to discuss alternative options that may be available to you. If you have any questions or need further assistance, please do not hesitate to contact our member services.
`,
    elaborateMiddleSchool: `Notice of Action about your Treatment Request

 

Dear Jessica Rhyne,

 

We are writing to inform you about the decision on your recent request for a medical procedure called Magnetic Resonance Imaging (MRI) of the lumbar spinal canal and contents without contrast material, followed by contrast material and further sections.

 

**Date:** September 30, 2023

**Member ID:** 123987456

**Member Name:** Jessica Rhyne

**Provider Name:** Dr. Jason Momoa

**Date of Denial:** September 30, 2023

 

Your request was denied
We have denied the medical services/items listed below requested by you or your doctor:
MRI of lumbar spinal canal and contents without contrast material, followed by contrast material and further sections.

 

Why did we deny your request?
We have denied the medical services/items listed above because the patient's medical history does not meet the necessary guidelines for this procedure. Here are the reasons:

1. **Medical History and Guidelines**: Your medical records show that you have had an MRI before, which showed spondylosis but no enhancing mass. The guidelines we follow say that more MRIs with contrast are only needed if certain conditions are met. These conditions include finding a syrinx on a noncontrast examination or having specific symptoms that have not improved after 6 weeks of treatment. Your history does not show these conditions.

2. **Non-Compliance with Clinical Guidelines**: It is important to follow clinical guidelines to ensure that every procedure you undergo is safe and necessary. In your case, the guidelines specify that advanced imaging like this MRI is necessary only for specific conditions, which do not match your current medical situation.

3. **Pediatric Requirement**: Some advanced imaging procedures are specifically for children. Your records show that you are not a pediatric patient, which is another reason why this particular procedure is not suitable for you.

 

This decision was made carefully to make sure that you receive the most appropriate and safe medical care. The provider that asked for these services, Dr. Emily White, will receive a copy of this letter. You or Dr. White can ask for a copy of the rules that were used to make this decision. Dr. White can also call a doctor from our team to discuss this decision further.

 

This notice also explains your right to appeal our decision. You have the opportunity to submit written comments, documents, or other information relating to the appeal. We encourage you to talk to your doctor about this decision and explore other treatment options that might be more suitable for your condition.

 

We understand that this might be disappointing news, and we are here to answer any questions you might have about this decision or your health coverage in general.
`,
  },
};
export const fileNamesWithResponseLetter = [
  "NewSampleAuth2.pdf",
  "PriorAuthSample10_1 (2).pdf",
  "PriorAuthSample11_1.pdf",
];