import React, { useCallback, useEffect } from "react";
import { Loading } from "../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ActiveDirectoryService } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState, loginUser } from "../store";
import { PAGE_URL, openNotificationWithIcon } from "../utils";
import { PageWrapper } from "../containers";

const Redirect = () => {

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verifier, user } = useSelector((state: RootState) => state.authUser);

  const validateAzureADCode = useCallback(
    async (code: string) => {
      try {
        const { data } = await ActiveDirectoryService.activeDirectoryAcquireToken(
          code,
          `${window.location.origin}/redirect`,
          verifier
        );
        if (!data.data) {
          openNotificationWithIcon(
            "",
            "Authorized Access! Check Azure AD group or contact admin.",
            "error"
          );
          return false;
        }

        const obj: IAuthUser = {
          accessToken: data.data.account.accessToken,
          refreshToken: data.data.account.refreshToken, 
          firstName: data.data.account.name,
          lastName: "",
          email: data.data.account.username,
          role: {
            code: data.data.role.id.toString(),
            name: data.data.role.name,
            id: data.data.role.id,
          },
        };
        dispatch(loginUser(obj));
        return true;
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    },
    [dispatch, verifier]
  );

  useEffect(() => {
    const validateADCodeAndLicenseKey = async () => {
      if (user?.accessToken) {
        return navigate(PAGE_URL.DASHBOARD);
      }
      const code = searchParams.get("code");
      if (code) {
        const isAzureADCodeValid = await validateAzureADCode(code || "");
        navigate(isAzureADCodeValid ? PAGE_URL.DASHBOARD : PAGE_URL.LOGIN);
      } else {
        navigate(PAGE_URL.LOGIN);
      }
    };
    validateADCodeAndLicenseKey();
  }, []);

  return <PageWrapper loading={true} pageContainerChildren={<Loading tip={"Authenticating"} />} />;
};

export default Redirect;
