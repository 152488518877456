import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EvidencePageResponseInfo, evidenceViewerService } from "../../api";
import { Loading, PdfViewer } from "../../components";
import { PAGE_URL, SEARCH_KEYWORD_DELIMINATOR, toTitleCase } from "../../utils";
import { EvidenceContent, Legends, CATEGORY_COLOR_MAPPING } from "./evidence-viewer-container";
import { Button, Input, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { SearchForm } from "./evidence-viewer-container/SearchForm";
import { useFetchEvidenceViewer } from "../../hooks";
import "./evidence-viewer-container/evidence-viewer-container.scss";

interface Props {
  searchKeywords: string | null;
  currentPage: number;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>;
  isTiffFile: boolean;
  fileUrl: string;
  evidenceContentLoading: boolean;
  pageData: EvidencePageResponseInfo;
  pageSearchKey: string | null;
  fileId: string;
  className: string;
}

interface HighlightInfo {
  keywords: string[];
  count: number;
}

export const EvidenceViewerContainer = (props: Props) => {
  const {
    searchKeywords,
    currentPage,
    setCurrPage,
    evidenceContentLoading,
    fileUrl,
    pageData,
    pageSearchKey,
    fileId,
  } = props;

  const { fetchSearchOccurences, occurencesList, searchOccurenceLoading } = useFetchEvidenceViewer({
    searchKeywords,
    documentUuid: fileId,
    signedUrl: fileUrl,  
  });

  const [selectedLegends, setSelectedLegends] = useState([] as string[]);
  const [keywords, setKeywords] = useState(searchKeywords);
  const [isKeywordsEmpty, setIsKeywordsEmpty] = useState(true);
  const [highlightInfo, setHighlightInfo] = useState<HighlightInfo>({ keywords: [], count: 0 });
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const insightType = queryParams.get("insight_type");
    if (insightType) {
      setSelectedLegends((prevSelectedLegends) => [...prevSelectedLegends, insightType]);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTerm = queryParams.get("search");
    const categories = [] as string[];
    if (searchTerm && Object.keys(pageData).length !== 0) {
      const terms = searchTerm.toLowerCase().split(SEARCH_KEYWORD_DELIMINATOR);
      pageData?.entities.forEach((entity) => {
        terms.forEach((term) => {
          if (entity.text.toLowerCase().includes(term)) {
            categories.push(entity.category);
          }
        });
      });
    }
    const filteredCategories = [...new Set(categories)].filter((item) =>
      Object.keys(CATEGORY_COLOR_MAPPING).includes(item)
    );
    setSelectedLegends((prevSelectedLegends) => [...prevSelectedLegends, ...filteredCategories]);
  }, [location.search, pageData?.entities, pageData]);

  const updateHighlightInfo = (occurences: any[], page: number, keyword: string) => {
    const pageOccurence = occurences.filter(item => item.pageNumber === page);
    if (pageOccurence) {
      const searchTextArray = pageOccurence.map(item => item.searchText);
      const totalOccurrences = pageOccurence.reduce((sum, item) => sum + item.inOccured, 0);
      setHighlightInfo({
        keywords: searchTextArray.length ? searchTextArray : keyword? [keyword]: [],
        count: totalOccurrences > 0 ? totalOccurrences : 1,
      });
    } else {
      setHighlightInfo({ keywords: [], count: 0});
    }
  }

  const handleSearchKeywords =async(searchKeyword: string[]) => {
    const keyword = searchKeyword.join(",");
    const occurences = await fetchSearchOccurences(keyword);
    setKeywords(keyword);
    setIsKeywordsEmpty(false);
    updateHighlightInfo(occurences || [], currentPage, keyword);
  };
  const handlePageChange = (newPage: number) => {
    setCurrPage(newPage);
    updateHighlightInfo(occurencesList, newPage, keywords || "");
  };

  const renderOccurrences = () => {
    return (
      <div>
        {searchOccurenceLoading ? (
          <Loading />
        ) : (
          <>
            <div className="result-occurrences-title flex">
              {occurencesList.length
                ? `Search Results (${occurencesList.length})`
                : "No results found"}
            </div>
            {!!occurencesList.length &&
              occurencesList.map((x, i) => (
                <Button
                  key={`search-occurences-${i}`}
                  className={`result-occurrences-content aic w-full ${
                    currentPage === x.pageNumber ? "tab-active" : "tab-inactive"
                  }`}
                  onClick={() => handlePageChange(x.pageNumber)}
                >
                  <span className="result-occurrences-count flex-center">
                    {x.inOccured ? x.inOccured : 1}
                  </span>
                  <p>Results in Page No. {x.pageNumber} -{x.searchText}</p>
                </Button>
              ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div className={props.className}>
      {!!Object.keys(pageData).length && (
        <div style={{ display: "flex" }}>
          <Legends {...{ selectedLegends, setSelectedLegends }} />
          <SearchForm
            keywords={keywords}
            setKeywords={handleSearchKeywords}
            setIsKeywordsEmpty={setIsKeywordsEmpty}
          />
        </div>
      )}
      <div
        style={{
          display: Object.keys(pageData).length ? "grid" : "block",
          gridTemplateColumns: !isKeywordsEmpty ? "1fr 1fr 0.5fr" : "1fr 1fr",
        }}
        className="evidence-page"
      >
        {fileUrl ? (
          <PdfViewer
            pdf={fileUrl}
            pageInfo={{ height: 700, width: 400, scale: 1.75 }}
            displayPagination
            setCurrPage={handlePageChange}
            currPage={currentPage}
            noExpand
          />
        ) : (
          <Loading />
        )}
        {!!Object.keys(pageData).length && (
          <EvidenceContent
            pageInfo={pageData}
            loading={evidenceContentLoading}
            searchKeywords={highlightInfo.keywords}
            highlightCount={highlightInfo.count}
            selectedLegends={selectedLegends}
          />
        )}
        {!isKeywordsEmpty && renderOccurrences()}
      </div>
    </div>
  );
};
